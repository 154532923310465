// Read more about this hook: https://github.com/pmndrs/zustand/blob/main/docs/integrations/persisting-store-data.md
// It's used to listen for asyn storages
import { useState, useEffect } from "react";
import { useStore } from "./useStore";
import { useSecureStore } from "./useSecureStore";

export const useHydration = () => {
  const [isStoreHydrated, setIsStoreHydrated] = useState(
    useStore.persist.hasHydrated
  );
  const [isSecureStoreHydrated, setIsSecureStoreHydrated] = useState(
    useSecureStore.persist.hasHydrated
  );

  useEffect(() => {
    const unsubStoreHydrate = useStore.persist.onHydrate(() =>
      setIsStoreHydrated(false)
    ); // Note: this is just in case you want to take into account manual rehydrations. You can remove this if you don't need it/don't want it.
    const unsubSecureStoreHydrate = useSecureStore.persist.onHydrate(() =>
      setIsSecureStoreHydrated(false)
    ); // Note: this is just in case you want to take into account manual rehydrations. You can remove this if you don't need it/don't want it.
    const unsubStoreFinishHydration = useStore.persist.onFinishHydration(() =>
      setIsStoreHydrated(true)
    );
    const unsubSecureStoreFinishHydration =
      useSecureStore.persist.onFinishHydration(() =>
        setIsSecureStoreHydrated(true)
      );

    setIsStoreHydrated(useStore.persist.hasHydrated());
    setIsSecureStoreHydrated(useSecureStore.persist.hasHydrated());

    return () => {
      unsubStoreHydrate();
      unsubSecureStoreHydrate();
      unsubStoreFinishHydration();
      unsubSecureStoreFinishHydration();
    };
  }, []);

  return [isStoreHydrated && isSecureStoreHydrated];
};
