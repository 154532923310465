// organize-imports-ignore
import { API } from "aws-amplify";
import * as mutations from "../../src/graphql/mutations";
import type {
  CreateNewsletterSubscriberInput,
  CreateNewsletterSubscriberMutation,
} from "../../src/API";
import type { GraphQLResult } from "@aws-amplify/api";

const wait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const createNewsletterSubscriber = {
  invalidationKeyList: [],
  function: async (input: CreateNewsletterSubscriberInput) => {
    const createdItem = (await API.graphql({
      query: mutations.createNewsletterSubscriber,
      variables: {
        input,
      },
    })) as GraphQLResult<CreateNewsletterSubscriberMutation>;

    if (createdItem.data?.createNewsletterSubscriber)
      return createdItem.data.createNewsletterSubscriber;

    throw new Error(
      "Something unexpected happened while trying to create a parking space"
    );
  },
};

export const createNothing = {
  invalidationKeyList: [],
  function: async (input: { result: string; error?: boolean }) => {
    await wait(3000);

    if (input.error) {
      throw new Error(
        "Something unexpected happened while trying to create a parking space"
      );
    }

    return input.result;
  },
};
