import { View, StyleSheet, ScrollView } from "react-native";

import { Screen } from "@components/screen";
import { Text } from "@components/text";
import { Button } from "@components/button";
import { SecondaryHeader } from "@components/secondary-header";
import { Input } from "@components/input";
import { colors } from "../../utility/styling";
import { currencies } from "data/currencies";
import moment from "moment";

export const PurchaseHistory = () => {
  const renderRow = (entry, i) => {
    return (
      <View style={styles.row} key={`${entry.date}-${i}`}>
        <View style={styles.dateColumn}>
          <Text style={styles.rowText}>{entry.date}</Text>
        </View>
        <View style={styles.amountColumn}>
          <Text style={styles.amountText}>{`${
            currencies[entry.amount.currency].symbol
          }${entry.amount.value.toFixed(2)}`}</Text>
        </View>
      </View>
    );
  };

  return (
    <Screen>
      <View style={styles.content}>
        <SecondaryHeader
          primaryTitle="Purchase history"
          secondaryTitle="See your spending waves"
        />

        <ScrollView
          contentContainerStyle={styles.scrollViewContainer}
          style={styles.scrollView}
        >
          <View style={styles.table}>
            <View style={[styles.row, styles.headerRow]}>
              <View style={styles.dateColumn}>
                <Text style={styles.headerRowText}>Date</Text>
              </View>
              <View style={styles.amountColumn}>
                <Text style={styles.headerRowText}>Amount spent</Text>
              </View>
            </View>

            {[
              {
                date: moment().format("DD MMM YYYY"),
                amount: {
                  value: 420,
                  currency: "EUR",
                },
              },
            ].map((entry, i) => renderRow(entry, i))}
          </View>
        </ScrollView>
      </View>
    </Screen>
  );
};

const styles = StyleSheet.create({
  content: {
    flex: 1,
    marginHorizontal: 32,
    justifyContent: "space-between",
  },

  scrollViewContainer: {
    flexGrow: 1,
    margin: 32,
  },
  scrollView: {
    height: "100%",
  },

  table: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "flex-start",
  },

  row: {
    height: 32,
    width: "100%",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: 16,
  },
  headerRow: {
    marginBottom: 18,
  },
  dateColumn: {
    flex: 1,
    marginRight: 16,
  },
  amountColumn: {
    flex: 1,
    marginRight: 16,
    alignItems: "flex-end",
  },
  headerRowText: {
    fontSize: 16,
    fontWeight: "800",
  },
  rowText: {
    fontSize: 16,
    fontWeight: "600",
    color: "#000",
  },
  amountText: {
    fontSize: 24,
    fontWeight: "800",
  },
});
