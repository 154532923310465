import { useState, forwardRef } from "react";
import {
  View,
  TextInput,
  StyleSheet,
  TextInputProps,
  StyleProp,
  ViewStyle,
  Pressable,
  Platform,
} from "react-native";

import { Text } from "@components/text";
import { colors } from "../../utility/styling";

import Eye from "../../../assets/svgs/eye-outline.svg";
import EyeOff from "../../../assets/svgs/eye-off-outline.svg";

type Props = {
  label?: string;
  error?: string;
  disabled?: boolean;
  touched?: boolean;
  containerStyle?: StyleProp<ViewStyle>;
} & TextInputProps;

export const Input = forwardRef<TextInput, Props>(
  (
    {
      label,
      style,
      containerStyle,
      error,
      touched,
      disabled = false,
      secureTextEntry,
      multiline,
      editable,
      numberOfLines = 1,
      ...otherTextInputProps
    },
    ref
  ) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    const renderEyeIcon = () => {
      let Icon = Eye;

      if (isPasswordVisible) {
        Icon = EyeOff;
      }

      return (
        <Pressable
          style={({ pressed }) => [
            styles.eyeIcon,
            {
              opacity: pressed ? 0.5 : 1,
            },
          ]}
          hitSlop={8}
          pressRetentionOffset={8}
          onPress={() => setIsPasswordVisible(!isPasswordVisible)}
        >
          <Icon height={24} width={24} color={colors.BLACK} />
        </Pressable>
      );
    };

    return (
      <View style={[styles.container, containerStyle]}>
        {label && <Text style={styles.label}>{label}</Text>}
        <View style={[styles.inputContainer, containerStyle]}>
          <TextInput
            ref={ref}
            style={[
              styles.input,
              multiline && {
                ...styles.inputMultiline,
                height: numberOfLines * 32 + 40,
              },
              style,
            ]}
            multiline={multiline}
            numberOfLines={numberOfLines}
            editable={editable && !disabled}
            secureTextEntry={secureTextEntry && !isPasswordVisible}
            {...otherTextInputProps}
          />
          {secureTextEntry && renderEyeIcon()}
        </View>
        <View style={styles.errorContainer}>
          <Text style={styles.error}>{error}</Text>
        </View>
      </View>
    );
  }
);

const styles = StyleSheet.create({
  container: {},
  label: {
    fontSize: 14,
    lineHeight: 32,
    fontWeight: "400",
    textAlign: "center",
  },
  inputContainer: {
    alignItems: "center",
    justifyContent: "center",
  },
  input: {
    fontSize: 14,
    fontWeight: "400",
    fontFamily: "Roboto-Regular",

    ...Platform.select({
      web: {
        height: 70,
        borderRadius: 35,
        paddingHorizontal: 24,
        boxShadow: "inset 0px 2px 12px rgba(0, 0, 0, 0.1)",
      },
      native: {
        height: 56,
        borderRadius: 28,
        paddingHorizontal: 20,
        borderColor: colors.BLACK,
        borderWidth: StyleSheet.hairlineWidth,
        backgroundColor: "#f9f9f9",
      },
    }),
  },

  inputMultiline: {
    lineHeight: 32,
    paddingTop: 20,
    paddingBottom: 20,
    textAlignVertical: "center",
  },
  eyeIcon: {
    position: "absolute",
    right: 20,
  },
  errorContainer: {
    justifyContent: "center",
    height: 16,
  },
  error: {
    fontSize: 12,
    lineHeight: 16,
    fontWeight: "400",
    color: colors.RED,
  },
});
