import { useEffect } from "react";
import { Hub, Logger } from "aws-amplify";
import { HubCallback, LegacyCallback } from "@aws-amplify/core/lib-esm/Hub";
import { useSecureStore } from "./useSecureStore";
import { useNavigation } from "@react-navigation/native";

const logger = new Logger("AuthLogger", "VERBOSE");

export const useAuthListener = () => {
  const navigation = useNavigation();
  const { setUsername } = useSecureStore();

  useEffect(() => {
    const listener: HubCallback | LegacyCallback = (capsule) => {
      const { event, data } = capsule.payload ?? {};

      switch (event) {
        case "configured":
          logger.info("the Auth module is configured");
          break;
        case "signIn":
          logger.info("user signed in");
          break;
        case "signIn_failure":
          logger.error("user sign in failed");
          break;
        case "signUp":
          logger.info("user signed up");
          break;
        case "signUp_failure":
          logger.error("user sign up failed");
          break;
        case "confirmSignUp":
          logger.info("user confirmation successful");
          break;
        case "completeNewPassword_failure":
          logger.error("user did not complete new password flow");
          break;
        case "autoSignIn":
          const user: Types.CognitoUser = data;

          setUsername(user.username);

          logger.info("auto sign in successful");
          break;
        case "autoSignIn_failure":
          navigation.navigate("SignIn");
          logger.error("auto sign in failed");
          break;
        case "forgotPassword":
          logger.info("password recovery initiated");
          break;
        case "forgotPassword_failure":
          logger.error("password recovery failed");
          break;
        case "forgotPasswordSubmit":
          logger.info("password confirmation successful");
          break;
        case "forgotPasswordSubmit_failure":
          logger.error("password confirmation failed");
          break;
        case "tokenRefresh":
          logger.info("token refresh succeeded");
          break;
        case "tokenRefresh_failure":
          logger.error("token refresh failed");
          break;
        case "cognitoHostedUI":
          logger.info("Cognito Hosted UI sign in successful");
          break;
        case "cognitoHostedUI_failure":
          logger.error("Cognito Hosted UI sign in failed");
          break;
        case "customOAuthState":
          logger.info("custom state returned from CognitoHosted UI");
          break;
        case "customState_failure":
          logger.error("custom state failure");
          break;
        case "parsingCallbackUrl":
          logger.info("Cognito Hosted UI OAuth url parsing initiated");
          break;
        case "userDeleted":
          logger.info("user deletion successful");
          break;
        case "signOut":
          logger.info("user signed out");
          break;
      }
    };

    // https://docs.amplify.aws/lib/auth/emailpassword/q/platform/js/#auto-sign-in-after-sign-up
    const cancel = Hub.listen("auth", listener);

    return () => {
      // https://docs.amplify.aws/lib/utilities/hub/q/platform/js/#stop-listening
      if (cancel) cancel();
    };
  }, [navigation, setUsername]);
};
