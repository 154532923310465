// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getPerformance } from "firebase/performance";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCecZKadopf8ISSUK5fF3-k6Eqf6jxoP-c",
  authDomain: "buy-literally-nothing.firebaseapp.com",
  projectId: "buy-literally-nothing",
  storageBucket: "buy-literally-nothing.appspot.com",
  messagingSenderId: "1079339043657",
  appId: "1:1079339043657:web:eba4800b68c1cbcdbb6898",
  measurementId: "G-VG0G9XNNPE",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const performance = getPerformance(app);
