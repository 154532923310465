import { useRef } from "react";
import { StyleSheet, View, Image, TextInput } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { useForm, Controller } from "react-hook-form";
import { Auth } from "aws-amplify";
import { useTranslation } from "react-i18next";

import { Screen } from "@components/screen";
import { Text } from "@components/text";
import { Button } from "@components/button";
import { Input } from "@components/input";
import { useSecureStore } from "../../hooks/useSecureStore";
import { useTempStore } from "hooks/useTempStore";

import { colors } from "utility/styling";

type FormValues = {
  email: string;
  password: string;
  confirmPassword: string;
};

export const SignUp = () => {
  const emailRef = useRef<TextInput>(null);
  const passwordRef = useRef<TextInput>(null);
  const confirmPasswordRef = useRef<TextInput>(null);
  const { t } = useTranslation();
  const navigation = useNavigation();
  const { setToast, setLoading } = useTempStore();
  const { setEmail, setPassword } = useSecureStore();

  const {
    control,
    getValues,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormValues>({
    defaultValues: {
      email: "",
      password: "",
      confirmPassword: "",
    },
  });

  const wait = (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms));

  const onSubmit = handleSubmit(async ({ email, password }) => {
    setLoading(true);
    try {
      const response = await Auth.signUp({
        username: email.toLowerCase(),
        password: password,
        autoSignIn: {
          enabled: true,
        },
      });
      console.log(JSON.stringify(response, null, 2));

      setEmail(email);
      navigation.navigate("Verification");
    } catch (error: any) {
      const { code } = error ?? {};

      console.log(JSON.stringify(error, null, 2));

      if (code === "UsernameExistsException") {
        setToast({
          message: t("error.generic"),
          type: "error",
        });
      }
    }
    setLoading(false);
  });

  return (
    <Screen style={styles.content}>
      <Image
        style={styles.logo}
        source={require("../../../assets/images/logo-full.png")}
      />
      <View style={styles.form}>
        <Controller
          control={control}
          rules={{
            required: t("validation.emailIsRequired"),
            maxLength: {
              value: 128,
              message: t("validation.emailMaxLength"),
            },
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: t("validation.emailFormat"),
            },
          }}
          render={({
            field: { onChange, onBlur, value },
            fieldState: { isTouched, error },
          }) => (
            <Input
              ref={emailRef}
              keyboardType="email-address"
              placeholder={t("email")}
              value={value}
              onBlur={onBlur}
              onChangeText={onChange}
              textContentType={"emailAddress"}
              error={error?.message}
              touched={isTouched}
              maxLength={128}
              disabled={isSubmitting}
              returnKeyType="next"
              onSubmitEditing={() => passwordRef.current?.focus()}
            />
          )}
          name="email"
        />

        <Controller
          control={control}
          rules={{
            required: t("validation.passwordIsRequired"),
            minLength: {
              value: 8,
              message: t("validation.passwordMinLength"),
            },
            maxLength: {
              value: 32,
              message: t("validation.passwordMaxLength"),
            },
            validate: {
              hasLowerLetter: (v) =>
                /[a-z]/.test(v) ||
                t("validation.passwordMustContainLowercaseLetter"),
              hasUpperLetter: (v) =>
                /[A-Z]/.test(v) ||
                t("validation.passwordMustContainUppercaseLetter"),
              hasNumber: (v) =>
                /[0-9]/.test(v) || t("validation.passwordMustContainNumber"),
            },
          }}
          render={({
            field: { onChange, onBlur, value },
            fieldState: { isTouched, error },
          }) => (
            <Input
              ref={passwordRef}
              placeholder={t("password")}
              value={value}
              onBlur={onBlur}
              onChangeText={onChange}
              error={error?.message}
              touched={isTouched}
              disabled={isSubmitting}
              textContentType={"password"}
              maxLength={32}
              secureTextEntry
              returnKeyType="next"
              onSubmitEditing={() => confirmPasswordRef.current?.focus()}
            />
          )}
          name="password"
        />

        <Controller
          control={control}
          rules={{
            required: t("validation.passwordConfirmationIsRequired"),
            validate: (v) =>
              v === getValues("password") ||
              t("validation.passwordsDoNotMatch"),
          }}
          render={({
            field: { onChange, onBlur, value },
            fieldState: { isTouched, error },
          }) => (
            <Input
              ref={confirmPasswordRef}
              placeholder={t("confirmPassword")}
              value={value}
              onBlur={onBlur}
              onChangeText={onChange}
              error={error?.message}
              touched={isTouched}
              disabled={isSubmitting}
              textContentType={"password"}
              maxLength={32}
              secureTextEntry
              returnKeyType="done"
            />
          )}
          name="confirmPassword"
        />

        <Button
          title={t("register")}
          style={styles.button}
          onPress={onSubmit}
        />

        <View style={styles.mutualAgreements}>
          <View style={styles.textContainer}>
            <Text style={styles.text}>{t("bySigningUpYouAccept")}</Text>
          </View>
          <View style={styles.textContainer}>
            <Text style={styles.text}>
              <Text
                style={[styles.text, styles.specialText]}
                onPress={() => navigation.navigate("TermsAndConditions")}
              >
                {t("termsOfUse")}
              </Text>
              {` ${t("andSign")} `}
              <Text
                style={[styles.text, styles.specialText]}
                onPress={() => navigation.navigate("PrivacyPolicy")}
              >
                {t("privacyPolicy")}
              </Text>
            </Text>
          </View>
        </View>
      </View>
    </Screen>
  );
};

const styles = StyleSheet.create({
  content: {
    flex: 1,
    justifyContent: "flex-start",
    alignItems: "center",
    marginHorizontal: 32,
  },

  logo: {
    height: 100,
    width: 250,
    resizeMode: "contain",
    marginBottom: 32,
  },
  form: {
    width: "100%",
    flex: 1,
    justifyContent: "center",
  },
  button: {
    marginBottom: 16,
  },

  mutualAgreements: {
    marginTop: 16,
    justifyContent: "center",
    alignItems: "center",
  },
  textContainer: {
    height: 32,
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    fontSize: 12,
    lineHeight: 12,
    fontWeight: "400",
    textAlign: "center",
    textAlignVertical: "center",
  },
  specialText: {
    color: colors.P3,
  },
});
