import { useMemo, forwardRef } from "react";
import {
  Text as DefaultText,
  StyleSheet,
  TextStyle,
  StyleProp,
  TextProps,
} from "react-native";

import { styling } from "../../utility/styling";

type Props = {
  style?: StyleProp<TextStyle>;
  log?: boolean;
} & TextProps;

export const Text = forwardRef<DefaultText, Props>(
  ({ style, log, ...props }, ref) => {
    const textStyle = useMemo(() => {
      let fontFamily;
      const currentStyle = style ? StyleSheet.flatten(style) : {};
      const fontWeight = currentStyle.fontWeight ?? "normal";
      const color = currentStyle.color ?? styling.colors.BLACK;

      // https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight
      switch (fontWeight) {
        case "100":
          fontFamily = styling.fonts.EXTRA_LIGHT;
          break;
        case "200":
        case "300":
          fontFamily = styling.fonts.LIGHT;
          break;
        case "400":
        case "normal":
          fontFamily = styling.fonts.REGULAR;
          break;
        case "500":
        case "600":
          fontFamily = styling.fonts.MEDIUM;
          break;
        case "700":
          fontFamily = styling.fonts.SEMI_BOLD;
          break;
        case "800":
        case "bold":
          fontFamily = styling.fonts.BOLD;
          break;
        case "900":
          fontFamily = styling.fonts.EXTRA_BOLD;
          break;
        default:
          fontFamily = styling.fonts.REGULAR;
      }

      const result = {
        ...currentStyle,
        color,
        fontWeight: undefined,
        fontFamily,
      };

      if (log) {
        console.log(`
Passed style: ${JSON.stringify(currentStyle, null, 2)}
New style: ${JSON.stringify(result, null, 2)}
`);
      }

      return result;
    }, [style, log]);

    return <DefaultText ref={ref} style={textStyle} {...props} />;
  }
);
