import color from "color";
import { StyleSheet } from "react-native";
import Animated, { FadeIn, FadeOut } from "react-native-reanimated";
import { useTempStore } from "../../hooks/useTempStore";
import { Pulse as Loader } from "react-native-animated-spinkit";
import { colors } from "../../utility/styling";

export const LoadingOverlay = () => {
  const { loading } = useTempStore();

  if (!loading) return null;

  return (
    <Animated.View style={styles.container} entering={FadeIn} exiting={FadeOut}>
      <Loader
        size={100}
        color={color(colors.WHITE).alpha(0.9).rgb().string()}
      />
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  container: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: "absolute",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#00000088",
    zIndex: 1,
  },
});
