import { View, StyleSheet } from "react-native";
import { getVersion } from "react-native-device-info";
import { useNavigation } from "@react-navigation/native";
import { Auth } from "aws-amplify";
import { useTempStore } from "../../hooks/useTempStore";
import { useSecureStore } from "../../hooks/useSecureStore";
import { useQueryClient } from "@tanstack/react-query";

import { Screen } from "@components/screen";
import { Text } from "@components/text";
import { Button } from "@components/button";
import { SecondaryHeader } from "@components/secondary-header";
import { colors } from "../../utility/styling";

export const Settings = () => {
  const navigation = useNavigation();
  const { setLoading } = useTempStore();
  const { signOut } = useSecureStore();
  const queryClient = useQueryClient();

  const logOut = async () => {
    setLoading(true);
    try {
      await Auth.signOut();
      queryClient.clear();
      signOut();
    } catch (error) {
      console.error("Something went wrong while trying to sign out");
    }
    setLoading(false);
  };

  return (
    <Screen>
      <View style={styles.content}>
        <SecondaryHeader
          primaryTitle="Settings"
          secondaryTitle="So you want to see what literally nothing does"
        />

        <View style={styles.actions}>
          <Button
            style={styles.button}
            onPress={() => navigation.navigate("AccountDetails")}
            title="Account details"
          />
          <Button
            style={styles.button}
            onPress={() => navigation.navigate("Support")}
            title="Support"
          />
          <Button
            style={styles.button}
            onPress={() => navigation.navigate("PurchaseHistory")}
            title="Purchase history"
          />
          <Button style={styles.button} onPress={() => {}} title="Review" />
        </View>

        <View style={styles.other}>
          <Button
            style={[styles.button, styles.logOutButton]}
            textStyle={styles.logOutButtonText}
            onPress={logOut}
            title="Log out"
          />
          <Text style={styles.version}>Version code {getVersion()}</Text>
        </View>
      </View>
    </Screen>
  );
};

const styles = StyleSheet.create({
  content: {
    flex: 1,
    marginHorizontal: 32,
    justifyContent: "space-between",
  },

  actions: {},
  button: {
    marginBottom: 16,
  },

  other: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  logOutButton: {
    width: "100%",
    marginBottom: 8,
  },
  logOutButtonText: {
    color: colors.P3,
  },
  version: {
    fontSize: 12,
    marginBottom: 8,
    color: "lightgray",
  },
});
