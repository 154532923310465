import { useState, useRef } from "react";
import { StyleSheet, View, Image, TextInput } from "react-native";
import Animated, { StretchInY } from "react-native-reanimated";
import { useNavigation } from "@react-navigation/native";
import { useForm, Controller } from "react-hook-form";
import { Auth } from "aws-amplify";
import { useTranslation } from "react-i18next";

import { Screen } from "@components/screen";
import { Button } from "@components/button";
import { Input } from "@components/input";
import { useSecureStore } from "../../hooks/useSecureStore";
import { useTempStore } from "hooks/useTempStore";

type FormValues = {
  email: string;
  code: string;
  newPassword: string;
};

export const ForgotPassword = () => {
  const emailRef = useRef<TextInput>(null);
  const codeRef = useRef<TextInput>(null);
  const newPasswordRef = useRef<TextInput>(null);
  const { t } = useTranslation();
  const navigation = useNavigation();
  const [allowPasswordChange, setAllowPasswordChange] = useState(false);
  const {
    control,
    getValues,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormValues>({
    defaultValues: {
      email: "",
      code: "",
      newPassword: "",
    },
  });
  const { setToast, setLoading } = useTempStore();
  const { setEmail, setPassword } = useSecureStore();

  const initializePasswordChange = async () => {};

  const onSubmit = handleSubmit(
    async ({ email: rawEmail, newPassword, code }) => {
      setLoading(true);

      const email = rawEmail.toLowerCase();

      if (allowPasswordChange) {
        try {
          const response = await Auth.forgotPasswordSubmit(
            email,
            code,
            newPassword
          );
          console.log(JSON.stringify(response, null, 2));

          setEmail(email);
          setPassword(newPassword);
          navigation.navigate("SignIn");
        } catch (error: any) {
          console.log(JSON.stringify(error, null, 2));

          if (error?.code === "UsernameExistsException") {
            setToast({
              message: t("error.generic"),
              type: "error",
            });
          }
        }
      } else {
        try {
          await Auth.forgotPassword(getValues("email"));
          setAllowPasswordChange(true);
        } catch (error: any) {
          console.log(JSON.stringify(error, null, 2));
          if (error?.code === "UserNotFoundException") {
            setToast({
              message: t("error.userDoesNotExist"),
              type: "error",
            });
          }
        }
      }

      setLoading(false);
    }
  );

  return (
    <Screen style={styles.content}>
      <Image
        style={styles.logo}
        source={require("../../../assets/images/logo-full.png")}
      />
      <View style={styles.form}>
        <Controller
          control={control}
          rules={{
            required: t("validation.emailIsRequired"),
            maxLength: {
              value: 128,
              message: t("validation.emailMaxLength"),
            },
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: t("validation.emailFormat"),
            },
          }}
          render={({
            field: { onChange, onBlur, value },
            fieldState: { isTouched, error },
          }) => (
            <Input
              ref={emailRef}
              keyboardType="email-address"
              placeholder={t("email")}
              value={value}
              onBlur={onBlur}
              onChangeText={onChange}
              textContentType={"emailAddress"}
              error={error?.message}
              touched={isTouched}
              maxLength={128}
              disabled={isSubmitting}
              returnKeyType={allowPasswordChange ? "next" : "done"}
              onSubmitEditing={() => {
                if (allowPasswordChange) {
                  codeRef.current?.focus();
                }
              }}
            />
          )}
          name="email"
        />

        {allowPasswordChange ? (
          <Animated.View entering={StretchInY}>
            <Controller
              control={control}
              rules={{
                required: t("validation.codeIsRequired"),
                minLength: {
                  value: 6,
                  message: t("validation.codeLength"),
                },
                maxLength: {
                  value: 6,
                  message: t("validation.codeLength"),
                },
                pattern: {
                  value: /\d{6}/,
                  message: t("validation.codeLength"),
                },
              }}
              render={({
                field: { onChange, onBlur, value },
                fieldState: { isTouched, error },
              }) => (
                <Input
                  ref={codeRef}
                  keyboardType="number-pad"
                  placeholder={t("verificationCode")}
                  value={value}
                  onBlur={onBlur}
                  onChangeText={onChange}
                  textContentType="oneTimeCode"
                  error={error?.message}
                  touched={isTouched}
                  maxLength={6}
                  disabled={isSubmitting}
                  returnKeyType="next"
                  onSubmitEditing={() => newPasswordRef.current?.focus()}
                />
              )}
              name="code"
            />

            <Controller
              control={control}
              rules={{
                required: t("validation.passwordIsRequired"),
                minLength: {
                  value: 8,
                  message: t("validation.passwordMinLength"),
                },
                maxLength: {
                  value: 32,
                  message: t("validation.passwordMaxLength"),
                },
                validate: {
                  hasLowerLetter: (v) =>
                    /[a-z]/.test(v) ||
                    t("validation.passwordMustContainLowercaseLetter"),
                  hasUpperLetter: (v) =>
                    /[A-Z]/.test(v) ||
                    t("validation.passwordMustContainUppercaseLetter"),
                  hasNumber: (v) =>
                    /[0-9]/.test(v) ||
                    t("validation.passwordMustContainNumber"),
                },
              }}
              render={({
                field: { onChange, onBlur, value },
                fieldState: { isTouched, error },
              }) => (
                <Input
                  ref={newPasswordRef}
                  placeholder={t("password")}
                  value={value}
                  onBlur={onBlur}
                  onChangeText={onChange}
                  error={error?.message}
                  touched={isTouched}
                  disabled={isSubmitting}
                  textContentType={"password"}
                  maxLength={32}
                  secureTextEntry
                  returnKeyType="done"
                />
              )}
              name="newPassword"
            />
          </Animated.View>
        ) : null}

        <Button
          title={allowPasswordChange ? t("change") : t("check")}
          style={styles.button}
          onPress={onSubmit}
        />
      </View>
    </Screen>
  );
};

const styles = StyleSheet.create({
  content: {
    flex: 1,
    justifyContent: "flex-start",
    alignItems: "center",
    marginHorizontal: 32,
  },

  logo: {
    height: 100,
    width: 250,
    resizeMode: "contain",
    marginBottom: 32,
  },
  form: {
    width: "100%",
    flex: 1,
    justifyContent: "center",
  },
  button: {
    marginBottom: 16,
  },
});
