/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:2aa2e3c5-1b30-430a-8d27-7f7f8fe5ac45",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_DHZR0GgyW",
    "aws_user_pools_web_client_id": "25oktd1vb3bl1qsk7lgf1ihm1q",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://4balsv4kqbdp7prtidx7nig5ym.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-js6vf7dxwnfpxm7b6atzqot4aq",
    "aws_cloud_logic_custom": [
        {
            "name": "recaptcha",
            "endpoint": "https://q0mvn3wzoe.execute-api.eu-west-1.amazonaws.com/prod",
            "region": "eu-west-1"
        }
    ]
};


export default awsmobile;
