import { generateShadow } from "@rainy5-group/react-native-shadow-generator";
import { useNavigation } from "@react-navigation/native";
import { View, StyleSheet, Image, TouchableOpacity } from "react-native";
import { useMediaQuery } from "react-responsive";
import { Button } from "@components/button";
import { useTranslation } from "react-i18next";

type Props = { middleSection?: React.ReactNode };

export const Header = ({ middleSection = null }: Props) => {
  const navigation = useNavigation();
  const { t } = useTranslation();
  const isDesktop = useMediaQuery({ query: "(min-width: 1150px)" });

  return (
    <View style={styles.container}>
      <TouchableOpacity
        onPress={() => {
          /*if (navigation.isFocused) {
            scrollRef?.scrollTo({ x: 0, y: 0, animated: true });
          } else {
          }*/
          navigation.navigate("Home");
        }}
      >
        <Image
          source={require("../../../assets/images/logo-full.png")}
          style={styles.image}
          resizeMode="contain"
        />
      </TouchableOpacity>
      {isDesktop && <View style={styles.middleSection}>{middleSection}</View>}
      <Button
        title={t("buy")}
        onPress={() => console.log("go")}
        style={styles.button}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 96,
    width: "100%",
    backgroundColor: "#fff",
    ...generateShadow(5, "#000"),

    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",

    paddingHorizontal: 32,

    zIndex: 100,
  },
  middleSection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  button: {
    width: 100,
  },
  image: {
    height: 80,
    width: 210,
  },
});
