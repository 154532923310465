import { useState, useEffect } from "react";
import { NavigationContainer, LinkingOptions } from "@react-navigation/native";
import { useFonts } from "expo-font";
import { StyleSheet, Platform } from "react-native";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { GestureHandlerRootView } from "react-native-gesture-handler";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import RNBootSplash from "react-native-bootsplash";

import { Toast } from "@components/toast";
import { useHydration } from "./hooks/useHydration";
import { LoadingOverlay } from "@components/loading-overlay";
import { Navigator as WebNavigator } from "./navigators/web";
import { Navigator as MobileNavigator } from "./navigators/mobile";
import { styling } from "./utility/styling";

const linking = Platform.select({
  web: {
    enabled: true,
    config: {
      screens: {
        Home: "",
        PrivacyPolicy: "privacy-policy",
        TermsAndConditions: "terms-and-conditions",
      },
    },
  },
  default: {
    enabled: false,
  },
}) as LinkingOptions<ReactNavigation.RootParamList>;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24 * 7, // 7 days
    },
  },
});

export const App = () => {
  const [isStoreReady] = useHydration();
  const [isNavigatorReady, setIsNavigatorReady] = useState(false);
  const [isFontPackReady] = useFonts({
    "Roboto-Regular": require("../assets/fonts/Roboto/Roboto-Regular.ttf"),
    "Roboto-Bold": require("../assets/fonts/Roboto/Roboto-Bold.ttf"),
    "Roboto-Light": require("../assets/fonts/Roboto/Roboto-Light.ttf"),
    "Roboto-Thin": require("../assets/fonts/Roboto/Roboto-Thin.ttf"),
    "Roboto-Medium": require("../assets/fonts/Roboto/Roboto-Medium.ttf"),
    "Roboto-Italic": require("../assets/fonts/Roboto/Roboto-Italic.ttf"),
  });

  useEffect(() => {
    if (isStoreReady && isNavigatorReady && Platform.OS !== "web") {
      RNBootSplash.hide({ fade: true });
    }
  }, [isStoreReady, isNavigatorReady]);

  if (!isFontPackReady || !isStoreReady) {
    return null;
  }

  return (
    <GestureHandlerRootView style={styles.container}>
      <SafeAreaProvider>
        <QueryClientProvider client={queryClient}>
          <NavigationContainer
            linking={linking}
            theme={styling.navigationTheme}
            onReady={() => {
              setIsNavigatorReady(true);
            }}
          >
            {Platform.OS === "web" ? <WebNavigator /> : <MobileNavigator />}
          </NavigationContainer>
          <LoadingOverlay />
          <Toast />
        </QueryClientProvider>
      </SafeAreaProvider>
    </GestureHandlerRootView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
