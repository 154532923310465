import { View, StyleSheet } from "react-native";

import { Screen } from "@components/screen";
import { Text } from "@components/text";
import { Button } from "@components/button";
import { SecondaryHeader } from "@components/secondary-header";
import { Input } from "@components/input";
import { colors } from "../../utility/styling";

export const Support = () => {
  return (
    <Screen>
      <View style={styles.content}>
        <SecondaryHeader
          primaryTitle="Support ticket"
          secondaryTitle="Would you like to get any improvements or have any problems?"
        />

        <View style={styles.inputs}>
          <Input label="Subject" />
          <Input label="E-mail" />
          <Input label="Message" multiline numberOfLines={4} />
        </View>

        <Button style={styles.button} onPress={() => {}} title="Submit" />
      </View>
    </Screen>
  );
};

const styles = StyleSheet.create({
  content: {
    flex: 1,
    marginHorizontal: 32,
    justifyContent: "space-between",
  },

  inputs: {},

  button: {
    marginBottom: 16,
  },
});
