import { useEffect, useCallback } from "react";
import { StyleSheet, View, Image } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { useNavigation } from "@react-navigation/native";
import { useForm, Controller } from "react-hook-form";
import { Auth } from "aws-amplify";
import { useTranslation } from "react-i18next";
import { useSecureStore } from "hooks/useSecureStore";
import { useTempStore } from "hooks/useTempStore";

import { Screen } from "@components/screen";
import { Button } from "@components/button";
import { Input } from "@components/input";

type FormValues = {
  code: string;
};

export const Verification = ({ route }) => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const { resend } = route.params ?? {};
  const { email } = useSecureStore();
  const { setToast, setLoading } = useTempStore();
  const {
    control,
    getValues,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormValues>({
    defaultValues: {
      code: "",
    },
  });

  const onSubmit = handleSubmit(async ({ code }) => {
    setLoading(true);
    try {
      if (email) {
        await Auth.confirmSignUp(email, code);
      } else {
        throw new Error("Email is not set");
      }
    } catch (error: any) {
      console.log(error);
      setToast({
        message: t("error.generic"),
        type: "error",
      });
    }
    setLoading(false);
  });

  const resendCode = useCallback(async () => {
    try {
      if (!email) throw new Error("Email is not set");

      await Auth.resendSignUp(email);
    } catch (error: any) {
      console.log(error);
      navigation.goBack();
    }
  }, [email, navigation]);

  useEffect(() => {
    if (resend) {
      resendCode();
    }
  }, [resend, resendCode]);

  return (
    <Screen style={styles.content}>
      <Image
        style={styles.logo}
        source={require("../../../assets/images/logo-full.png")}
      />
      <View style={styles.form}>
        <Controller
          control={control}
          rules={{
            required: t("validation.codeIsRequired"),
            minLength: {
              value: 6,
              message: t("validation.codeLength"),
            },
            maxLength: {
              value: 6,
              message: t("validation.codeLength"),
            },
            pattern: {
              value: /\d{6}/,
              message: t("validation.codeLength"),
            },
          }}
          render={({
            field: { onChange, onBlur, value },
            fieldState: { isTouched, error },
          }) => (
            <Input
              keyboardType="number-pad"
              placeholder={t("verificationCode")}
              value={value}
              onBlur={onBlur}
              onChangeText={onChange}
              textContentType="oneTimeCode"
              error={error?.message}
              touched={isTouched}
              maxLength={6}
              disabled={isSubmitting}
              returnKeyType="done"
            />
          )}
          name="code"
        />

        <Button title={t("confirm")} style={styles.button} onPress={onSubmit} />
      </View>
    </Screen>
  );
};

const styles = StyleSheet.create({
  content: {
    flex: 1,
    justifyContent: "flex-start",
    alignItems: "center",
    marginHorizontal: 32,
  },

  logo: {
    height: 100,
    width: 250,
    resizeMode: "contain",
    marginBottom: 32,
  },
  form: {
    width: "100%",
    flex: 1,
    justifyContent: "center",
  },
  button: {
    marginBottom: 16,
  },
});
