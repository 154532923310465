import {
  createBottomTabNavigator,
  BottomTabNavigationOptions,
} from "@react-navigation/bottom-tabs";
import { LeaderBoard } from "@screens/leader-board";
import { Home } from "./Home";
import * as React from "react";
import { Text, View, Image } from "react-native";

import HomeIcon from "../../../assets/svgs/home.svg";
import ListIcon from "../../../assets/svgs/list.svg";

function SettingsScreen() {
  return (
    <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
      <Text>Settings!</Text>
    </View>
  );
}

type TabBarIcon = Pick<BottomTabNavigationOptions, "tabBarIcon">["tabBarIcon"];
type ModifiedTabBarIconParameters = Parameters<TabBarIcon>[0] & { route: any };
type ModifiedTabBarIcon = (
  props: ModifiedTabBarIconParameters
) => React.ReactNode;

const Tab = createBottomTabNavigator();
export const Bottom = () => {
  const renderTabBarIcons: ModifiedTabBarIcon = ({
    focused,
    color,
    size,
    route,
  }) => {
    let icon: React.ReactNode;
    size = focused ? size * 1.25 : size;

    if (route.name === "HomeNavigator") {
      icon = <HomeIcon width={size} height={size} fill={color} />;
    } else if (route.name === "Buy") {
      icon = (
        <Image
          source={require("../../../assets/images/icon.png")}
          style={{ height: size, width: size }}
        />
      );
    } else if (route.name === "LeaderBoard") {
      icon = <ListIcon width={size} height={size} fill={color} />;
    }

    return icon;
  };

  return (
    <Tab.Navigator
      screenOptions={({ route }) => ({
        tabBarStyle: {
          height: 80,
        },
        tabBarIcon: (props) => renderTabBarIcons({ ...props, route }),
        tabBarShowLabel: false,
        headerShown: false,
      })}
    >
      <Tab.Screen name="HomeNavigator" component={Home} />
      <Tab.Screen name="Buy" component={SettingsScreen} />
      <Tab.Screen name="LeaderBoard" component={LeaderBoard} />
    </Tab.Navigator>
  );
};
