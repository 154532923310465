import { Screen } from "@components/screen";
import { Text } from "@components/text";
import { useTempStore } from "@hooks/useTempStore";
import { produce } from "immer";
import { useState, useRef } from "react";
import { StyleSheet, Pressable, Dimensions } from "react-native";

import { About } from "./sections/About";
import { HowItWorks } from "./sections/HowItWorks";
import { LeaderBoard } from "./sections/LeaderBoard.web";
import { Products } from "./sections/Products";

const HEIGHT = Dimensions.get("window").height;

export const Home = () => {
  const { scrollRef } = useTempStore();
  const sections = useRef<Array<Types.ScrollSection>>([]);
  const [activeSectionTitle, setActiveSectionTitle] = useState<string | null>(
    null
  );

  const updateScrollSections = (section: Types.ScrollSection) => {
    sections.current = produce(sections.current, (draft) => {
      let oldSection = draft.find((s) => s.title === section.title);
      if (oldSection) {
        oldSection = section;
      } else {
        draft.push(section);
      }
      draft.sort((a, b) => a.position - b.position);
    });
  };

  return (
    <Screen
      scrollViewProps={{
        onScroll: (event) => {
          const { y } = event.nativeEvent.contentOffset;
          const section = sections.current.find(
            (s) =>
              y + 1 >= s?.layout.y && y + 1 <= s?.layout.y + s?.layout.height
          );
          if (section) {
            setActiveSectionTitle(section.title);
          }
        },
        scrollEventThrottle: 32,
      }}
      headerMiddleSection={
        <>
          {sections.current.map((section, i) => (
            <Pressable
              onPress={() => {
                if (scrollRef) {
                  scrollRef.scrollTo({
                    x: 0,
                    y: section.layout.y,
                    animated: true,
                  });
                }
              }}
              style={styles.sectionButton}
              key={section.title}
            >
              <Text
                style={[
                  styles.sectionButtonText,
                  ((!activeSectionTitle && i === 0) ||
                    section.title === activeSectionTitle) &&
                    styles.selectedSectionButtonText,
                ]}
              >
                {section.title}
              </Text>
            </Pressable>
          ))}
        </>
      }
    >
      <About
        style={styles.section}
        onLayout={(event) =>
          updateScrollSections({
            title: "What is this",
            position: 0,
            layout: event.nativeEvent?.layout,
          })
        }
      />
      <HowItWorks
        style={styles.section}
        onLayout={(event) =>
          updateScrollSections({
            title: "How it works",
            position: 1,
            layout: event.nativeEvent?.layout,
          })
        }
      />
      <Products
        style={styles.section}
        onLayout={(event) =>
          updateScrollSections({
            title: "Products",
            position: 2,
            layout: event.nativeEvent?.layout,
          })
        }
      />
      <LeaderBoard
        style={styles.section}
        onLayout={(event) =>
          updateScrollSections({
            title: "Leaderboard",
            position: 3,
            layout: event.nativeEvent?.layout,
          })
        }
      />
    </Screen>
  );
};

const styles = StyleSheet.create({
  section: {
    justifyContent: "center",
    height: HEIGHT - 96,
  },
  sectionButton: {
    marginLeft: 16,
    padding: 16,
  },
  sectionButtonText: {
    fontSize: 18,
    color: "#BDBDBD",
  },
  selectedSectionButtonText: {
    fontWeight: "bold",
    color: "#000",
  },
});
