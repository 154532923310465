import { Text } from "@components/text";
import { FontAwesome } from "@expo/vector-icons";
import { useDimensions } from "@hooks/useDimensions";
import { generateShadow } from "@rainy5-group/react-native-shadow-generator";
import { forwardRef } from "react";
import { View, StyleSheet, ViewProps, ScrollView } from "react-native";

type Props = Omit<ViewProps, "ref">;

export const HowItWorks = forwardRef<View, Props>(
  ({ style, ...props }, ref) => {
    const { windowDimensions } = useDimensions();

    return (
      <View style={[styles.container, style]} ref={ref} {...props}>
        <Text style={styles.title}>How does it work?</Text>
        <View style={[styles.content, { width: windowDimensions.width }]}>
          <ScrollView
            contentContainerStyle={[styles.horizontalScroll]}
            showsHorizontalScrollIndicator
            horizontal
          >
            <View style={styles.scrollViewContent}>
              <View style={styles.circle}>
                <Text style={styles.text}>You have an urge to spend</Text>
              </View>
              <FontAwesome name="long-arrow-right" size={40} color="black" />
              <View style={styles.circle}>
                <Text style={styles.text}>
                  Select the pacake that suits your situation
                </Text>
              </View>
              <FontAwesome name="long-arrow-right" size={40} color="black" />
              <View style={styles.circle}>
                <Text style={styles.text}>Buy it</Text>
              </View>
              <FontAwesome name="long-arrow-right" size={40} color="black" />
              <View style={styles.circle}>
                <Text style={styles.text}>
                  You get <Text style={styles.bold}>Literally Nothing</Text>
                </Text>
              </View>
            </View>
          </ScrollView>
        </View>
      </View>
    );
  }
);

const styles = StyleSheet.create({
  container: {
    width: "100%",
    paddingHorizontal: 32,
    alignItems: "center",
    paddingVertical: 64,
  },
  title: {
    fontSize: 24,
    fontWeight: "700",
    paddingBottom: 64,
  },
  content: {
    flexDirection: "row",
  },
  horizontalScroll: {
    height: 300,
    flexGrow: 1,
    justifyContent: "center",
  },
  scrollViewContent: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
    paddingHorizontal: 32,
    gap: 32,
  },
  text: {
    fontSize: 16,
    fontWeight: "400",
    textAlign: "center",
  },
  bold: {
    fontWeight: "700",
  },
  circle: {
    height: 180,
    width: 180,
    borderRadius: 90,
    padding: 30,
    ...generateShadow(5),
    backgroundColor: "white",
    justifyContent: "center",
    alignItems: "center",
  },
});
