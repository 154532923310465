import { Text } from "@components/text";
import { generateShadow } from "@rainy5-group/react-native-shadow-generator";
import { forwardRef } from "react";
import { View, StyleSheet, ViewProps } from "react-native";

type Props = Omit<ViewProps, "ref">;

export const About = forwardRef<View, Props>(({ style, ...props }, ref) => {
  return (
    <View style={[styles.container, style]} ref={ref} {...props}>
      <Text style={styles.title}>
        So you are wondering, what is the Buy Litterally Nothing project?
      </Text>
      <View style={styles.content}>
        <Text style={styles.text}>
          Do you ever feel like you are spending too much money on stuff the
          that feels like it has the value, but after you aquire it becomes
          meaningless?
        </Text>
        <View style={styles.spacer} />
        <Text style={styles.text}>
          Not only that you spend too much, but afterwards you feel like your
          hard earn money goes to waste and you feel like you shouldn't have
          done this.
        </Text>
        <View style={styles.spacer} />
        <Text style={styles.text}>
          This project is meant to stop you and give a second thought. You can
          choose the plan, spend the amount to buy a valuable lesson - you need
          to spend less on stuff that is meaningless.
        </Text>
      </View>
    </View>
  );
});

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 32,
    alignItems: "center",
    paddingVertical: 64,
  },
  title: {
    fontSize: 24,
    fontWeight: "700",
    paddingBottom: 64,
    textAlign: "center",
  },
  content: {
    width: "100%",
    alignItems: "center",
    maxWidth: 820,
  },
  spacer: {
    height: 24,
  },
  text: {
    fontSize: 18,
    fontWeight: "400",
    textAlign: "center",
  },
  bold: {
    fontWeight: "700",
  },
  circle: {
    height: 180,
    width: 180,
    borderRadius: 90,
    padding: 30,
    ...generateShadow(5),
    backgroundColor: "white",
    justifyContent: "center",
    alignItems: "center",
  },
});
