import { useCallback, useState } from "react";
import { Text } from "@components/text";
import { generateShadow } from "@rainy5-group/react-native-shadow-generator";
import { View, StyleSheet } from "react-native";
import { useForm, Controller } from "react-hook-form";
import { useMediaQuery } from "react-responsive";
import {
  createNewsletterSubscriber,
  createNothing,
} from "../../data/mutations";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { Input } from "@components/input";
import { Button } from "@components/button";
import { useTempStore } from "../../hooks/useTempStore";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import axios from "axios";
import { API } from "aws-amplify";

type FormValues = {
  email: string;
};

export const Newsletter = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const isTablet = useMediaQuery({ query: "(max-width: 1150px)" });

  const { executeRecaptcha } = useGoogleReCaptcha();
  const [loading, setLoading] = useState(false);

  const queryClient = useQueryClient();
  const { setToast } = useTempStore();
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm<FormValues>({
    defaultValues: {
      email: "",
    },
  });

  const createNewsletterSubscriberMutationn = useMutation(
    createNewsletterSubscriber.function,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          createNewsletterSubscriber.invalidationKeyList
        );
        reset();
        setToast({
          type: "success",
          message: t("success.successfullyAdded"),
        });
      },
      onError: (error: any) => {
        console.log(JSON.stringify(error, null, 2));
        setToast({
          type: "error",
          message: t("error.generic"),
        });
      },
      onSettled: () => {
        setLoading(false);
      },
    }
  );

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }

    try {
      const token = await executeRecaptcha("yourAction");
      return token;
    } catch (error) {
      console.log(error);
      return null;
    }
  }, [executeRecaptcha]);

  const verifyRecaptcha = async (
    token: string
  ): Promise<Types.RecaptchaVerificationResponse | null> => {
    const apiName = "recaptcha";
    const path = "/recaptcha";
    const myInit = {
      body: { token }, // replace this with attributes you need
      headers: {}, // OPTIONAL
    };

    try {
      const response: Types.RecaptchaVerificationResponse = await API.post(
        apiName,
        path,
        myInit
      );

      if (response.success) {
        if ((response?.score ?? 0) < 0.5) {
          throw new Error("Bots not allowed");
        } else {
          return response;
        }
      } else {
        throw new Error("Recaptcha verification failed");
      }
    } catch (error) {
      console.log(JSON.stringify(error, null, 2));
      setToast({
        type: "error",
        message: t("error.generic"),
      });
      return null;
    }
  };

  const onSubmit = handleSubmit(async (data) => {
    setLoading(true);

    const token = await handleReCaptchaVerify();

    if (token) {
      const info = await verifyRecaptcha(token);
      if (info) {
        createNewsletterSubscriberMutationn.mutate({
          email: data.email,
          clientIp: info?.clientIp,
          score: info?.score,
          hostname: info?.hostname,
        });
        return;
      }
    }

    setLoading(false);
    setToast({
      type: "error",
      message: t("error.generic"),
    });
  });

  return (
    <View style={styles.container}>
      <View
        style={[
          styles.content,
          isTablet && styles.tContent,
          isMobile && styles.mContent,
        ]}
      >
        <Text style={[styles.title, isTablet && styles.tTitle]}>
          Do you want to hear about new projects?
        </Text>
        <View style={[styles.action, isMobile && styles.mAction]}>
          <Controller
            control={control}
            rules={{
              required: t("validation.emailIsRequired"),
              maxLength: {
                value: 128,
                message: t("validation.emailMaxLength"),
              },
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: t("validation.emailFormat"),
              },
            }}
            render={({
              field: { onChange, onBlur, value },
              fieldState: { isTouched, error },
            }) => (
              <Input
                keyboardType="email-address"
                placeholder={t("enterEmail")}
                placeholderTextColor="#959EA8"
                value={value}
                onBlur={onBlur}
                onChangeText={onChange}
                textContentType={"emailAddress"}
                error={error?.message}
                touched={isTouched}
                maxLength={128}
                disabled={isSubmitting}
                returnKeyType="done"
                containerStyle={isMobile && styles.mInputContainer}
                style={[styles.emailInput, isMobile && styles.mEmailInput]}
              />
            )}
            name="email"
          />
          <Button
            title={t("subscribeNow")}
            special
            onPress={onSubmit}
            loading={loading}
            style={[styles.button, isMobile && styles.mButton]}
          />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    padding: 32,
  },
  content: {
    height: 200,
    width: "100%",
    backgroundColor: "#fff",
    borderRadius: 16,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 96,

    ...generateShadow(5, "#000"),
  },

  title: {
    fontSize: 24,
    fontWeight: "400",
    color: "#000",
  },

  action: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  emailInput: {
    width: 300,
    marginTop: 16,
    marginRight: 16,
  },
  button: {
    minWidth: 160,
  },

  // Tablet -------------------------------------------------------------------
  tContent: {
    height: "unset",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 64,
    paddingVertical: 64,
    gap: 64,
  },
  tTitle: {
    textAlign: "center",
  },

  // Mobile -------------------------------------------------------------------
  mContent: {
    paddingHorizontal: 32,
    paddingVertical: 32,
    gap: 16,
  },
  mAction: {
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    gap: 16,
  },
  mEmailInput: {
    width: "80%",
    marginRight: "unset",
  },
  mInputContainer: {
    width: "100%",
  },
  mButton: {
    width: "80%",
  },
});
