import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { useQuery } from "@tanstack/react-query";

import { useSecureStore } from "../../hooks/useSecureStore";
import { queryAuthenticationDetails } from "../../data/queries";
import { Intro } from "@screens/intro";
import { LeaderBoard } from "@screens/leader-board";
import { Onboarding } from "./Onboarding";
import { Bottom } from "./Bottom";

const Stack = createNativeStackNavigator();

export const Navigator = () => {
  const { username } = useSecureStore();

  const authenticationInfo = useQuery(
    queryAuthenticationDetails().key,
    queryAuthenticationDetails().fn,
    { retry: 3, staleTime: 1000 * 60 * 15, enabled: !!username }
  );

  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
      }}
    >
      {username && username === authenticationInfo.data?.username ? (
        <Stack.Screen name="Bottom" component={Bottom} />
      ) : (
        <>
          <Stack.Screen name="Intro" component={Intro} />
          <Stack.Screen name="LeaderBoard" component={LeaderBoard} />
          <Stack.Screen name="Onboarding" component={Onboarding} />
        </>
      )}
    </Stack.Navigator>
  );
};
