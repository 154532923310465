import { useEffect, useState } from "react";
import { Dimensions } from "react-native";

export const useDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    Dimensions.get("window")
  );
  const [screenDimensions, setScreenDimensions] = useState(
    Dimensions.get("window")
  );

  useEffect(() => {
    const dimensionsSubscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setWindowDimensions(window);
        setScreenDimensions(screen);
      }
    );
    return () => dimensionsSubscription?.remove();
  });

  return { windowDimensions, screenDimensions };
};
