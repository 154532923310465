import { useRef, useEffect } from "react";
import { StyleSheet, View, Image, TextInput } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { useForm, Controller } from "react-hook-form";
import { Auth } from "aws-amplify";
import { useTranslation } from "react-i18next";
import { useTempStore } from "../../hooks/useTempStore";
import { useSecureStore } from "../../hooks/useSecureStore";

import { Screen } from "@components/screen";
import { Button } from "@components/button";
import { Input } from "@components/input";
import { Text } from "@components/text";

import { colors } from "../../utility/styling";

type FormValues = {
  email: string;
  password: string;
};

export const SignIn = () => {
  const emailRef = useRef<TextInput>(null);
  const passwordRef = useRef<TextInput>(null);

  const { t } = useTranslation();
  const navigation = useNavigation();
  const { setLoading, setToast } = useTempStore();
  const {
    setEmail,
    setPassword,
    setUsername,
    email: savedEmail,
    password: savedPassword,
  } = useSecureStore();
  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useForm<FormValues>({
    defaultValues: {
      email: savedEmail,
      password: savedPassword,
    },
  });

  const onSubmit = handleSubmit(async ({ email, password }) => {
    setLoading(true);
    try {
      const response: Types.CognitoUser = await Auth.signIn({
        username: email.toLowerCase(),
        password: password,
      });

      if (response && response.username) {
        setEmail(email);
        setUsername(response.username);
        setPassword(password);
      }
    } catch (error: any) {
      console.log(JSON.stringify(error, null, 2));

      if (error?.code === "UserNotConfirmedException") {
        setEmail(email);
        setPassword(password);
        navigation.navigate("Verification", { resend: true });
      } else if (error?.code === "NotAuthorizedException") {
        setToast({
          message: t("error.badCredentials"),
          type: "error",
        });
      }
    }
    setLoading(false);
  });

  useEffect(() => {
    if (savedEmail) {
      setValue("email", savedEmail);
    }
  }, [savedEmail]);

  useEffect(() => {
    if (savedPassword) {
      setValue("password", savedPassword);
    }
  }, [savedPassword]);

  return (
    <Screen style={styles.content}>
      <Image
        style={styles.logo}
        source={require("../../../assets/images/logo-full.png")}
      />
      <View style={styles.form}>
        <Controller
          control={control}
          rules={{
            required: t("validation.emailIsRequired"),
            maxLength: {
              value: 128,
              message: t("validation.emailMaxLength"),
            },
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: t("validation.emailFormat"),
            },
          }}
          render={({
            field: { onChange, onBlur, value },
            fieldState: { isTouched, error },
          }) => (
            <Input
              ref={emailRef}
              keyboardType="email-address"
              placeholder={t("email")}
              value={value}
              onBlur={onBlur}
              onChangeText={onChange}
              textContentType={"emailAddress"}
              error={error?.message}
              touched={isTouched}
              maxLength={128}
              disabled={isSubmitting}
              returnKeyType="next"
              onSubmitEditing={() => passwordRef.current?.focus()}
            />
          )}
          name="email"
        />

        <Controller
          control={control}
          rules={{
            required: t("validation.passwordIsRequired"),
            minLength: {
              value: 8,
              message: t("validation.passwordMinLength"),
            },
            maxLength: {
              value: 32,
              message: t("validation.passwordMaxLength"),
            },
            validate: {
              hasLowerLetter: (v) =>
                /[a-z]/.test(v) ||
                t("validation.passwordMustContainLowercaseLetter"),
              hasUpperLetter: (v) =>
                /[A-Z]/.test(v) ||
                t("validation.passwordMustContainUppercaseLetter"),
              hasNumber: (v) =>
                /[0-9]/.test(v) || t("validation.passwordMustContainNumber"),
            },
          }}
          render={({
            field: { onChange, onBlur, value },
            fieldState: { isTouched, error },
          }) => (
            <Input
              ref={passwordRef}
              placeholder={t("password")}
              value={value}
              onBlur={onBlur}
              onChangeText={onChange}
              error={error?.message}
              touched={isTouched}
              disabled={isSubmitting}
              textContentType={"password"}
              maxLength={32}
              secureTextEntry
              returnKeyType="done"
            />
          )}
          name="password"
        />

        <Button title="Sign In" style={styles.button} onPress={onSubmit} />
        <Button
          title="Register"
          onPress={() => navigation.navigate("SignUp")}
          special
        />
        <View style={styles.forgotPasswordContainer}>
          <Text style={styles.forgotPassword}>
            Have an account, but
            <Text
              style={styles.specialText}
              onPress={() => navigation.navigate("ForgotPassword")}
            >{` ${t("forgotPassword").toLowerCase()}?`}</Text>
          </Text>
        </View>
      </View>
    </Screen>
  );
};

const styles = StyleSheet.create({
  content: {
    flex: 1,
    justifyContent: "flex-start",
    alignItems: "center",
    marginHorizontal: 32,
  },

  logo: {
    height: 100,
    width: 250,
    resizeMode: "contain",
    marginBottom: 32,
  },
  form: {
    width: "100%",
    flex: 1,
    justifyContent: "center",
  },
  button: {
    marginBottom: 16,
  },
  forgotPasswordContainer: {
    marginTop: 16,
    height: 32,
    justifyContent: "center",
    alignItems: "center",
  },
  forgotPassword: {
    fontSize: 12,
    lineHeight: 12,
    fontWeight: "400",
    textAlign: "center",
    textAlignVertical: "center",
  },
  specialText: {
    color: colors.P3,
  },
});
