import { useEffect, useRef } from "react";
import { StyleSheet, View, Image } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { useTranslation } from "react-i18next";

import { Screen } from "@components/screen";
import { Text } from "@components/text";
import { Button } from "@components/button";

import Lottie from "lottie-react-native";
import { colors } from "utility/styling";

export const Intro = () => {
  const trophyRef = useRef<Lottie>(null);
  const lockRef = useRef<Lottie>(null);

  const navigation = useNavigation();
  const { t } = useTranslation();

  const wait = (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms));

  useEffect(() => {
    const animate = async () => {
      await wait(1000);
      trophyRef.current?.play(0, 110);
      await wait(500);
      lockRef.current?.play();
    };

    animate();
  }, []);

  return (
    <Screen headerShowLanguageSwitcher style={styles.content}>
      <Image
        style={styles.logo}
        source={require("../../../assets/images/logo-full.png")}
      />
      <Button
        style={styles.button}
        onPress={() => navigation.navigate("LeaderBoard")}
      >
        <View style={styles.lottieWrapper}>
          <Lottie
            ref={trophyRef}
            source={require("../../../assets/lottie/trophy.json")}
            autoPlay={false}
            loop={false}
            style={styles.trophy}
          />
        </View>
        <Text style={styles.buttonTitle}>{t("viewLeaderBoard")}</Text>
      </Button>
      <Button
        style={[styles.button, styles.inversedButton]}
        onPress={() => navigation.navigate("Onboarding")}
      >
        <View style={styles.lottieWrapper}>
          <Lottie
            ref={lockRef}
            source={require("../../../assets/lottie/unlock-white.json")}
            autoPlay={false}
            loop={false}
            style={styles.lock}
          />
        </View>
        <Text style={[styles.buttonTitle, styles.inversedButtonTitle]}>
          {t("onboard")}
        </Text>
      </Button>
    </Screen>
  );
};

const styles = StyleSheet.create({
  content: {
    flex: 1,
    justifyContent: "flex-start",
    alignItems: "center",
    marginHorizontal: 32,
  },

  logo: {
    height: 100,
    width: 250,
    resizeMode: "contain",
    marginBottom: 32,
  },
  lottieWrapper: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  trophy: {
    width: 150,
    height: 150,
    justifyContent: "center",
    alignItems: "center",
  },
  lock: {
    width: 130,
    height: 130,
    justifyContent: "center",
    alignItems: "center",
  },

  button: {
    width: "100%",
    flex: 1,
    marginBottom: 32,
    justifyContent: "space-between",
    alignItems: "center",
    padding: 32,
  },
  inversedButton: {
    backgroundColor: colors.BLACK,
  },
  buttonTitle: {
    color: colors.BLACK,
    fontSize: 16,
    fontWeight: "700",
  },
  inversedButtonTitle: {
    color: colors.WHITE,
  },
});
