import produce from "immer";
import { ScrollView } from "react-native";
import create from "zustand";
import { devtools } from "zustand/middleware";

interface State {
  scrollRef: ScrollView;
  loading: boolean;
  codeDeliveryDetails?: Types.CodeDeliveryDetails;
  toast?: Types.Toast;
}
interface Actions {
  setScrollRef: (ref: ScrollView) => void;
  setCodeDeliveryDetails: (
    codeDeliveryDetails: Types.CodeDeliveryDetails
  ) => void;
  setToast: (toast: Types.Toast) => void;
  setLoading: (loading: boolean) => void;
  clearToast: () => void;
  clear: () => void;
}

const cleanState: State = {
  scrollRef: null,
  loading: false,
  codeDeliveryDetails: undefined,
  toast: undefined,
};

export const useTempStore = create<State & Actions>()(
  devtools(
    (set) => ({
      ...cleanState,
      setScrollRef: (ref: ScrollView) => set(() => ({ scrollRef: ref })),
      setCodeDeliveryDetails: (
        codeDeliveryDetails: Types.CodeDeliveryDetails
      ) => set({ codeDeliveryDetails }),
      setToast: (toast: Types.Toast) => set({ toast }),
      setLoading: (loading: boolean) => set({ loading }),
      clearToast: () => set(() => ({ toast: undefined })),
      clear: () => set(() => produce(cleanState, () => {})),
    }),
    {
      name: "temporary-storage",
    }
  )
);
