import { Text } from "@components/text";
import { useMediaQueries } from "@hooks/useMediaQueries";
import { forwardRef } from "react";
import {
  View,
  StyleSheet,
  Image,
  TouchableOpacity,
  ViewProps,
} from "react-native";

import { currencies } from "../../../data/currencies";
import { entries } from "../../../data/leaderBoardEntries";

type Props = Omit<ViewProps, "ref">;

export const LeaderBoard = forwardRef<View, Props>(
  ({ style, ...props }, ref) => {
    const { isMobile } = useMediaQueries();

    return (
      <View style={[styles.container, style]} ref={ref} {...props}>
        <Text style={styles.title}>Leaderboard</Text>
        <View style={styles.table}>
          <View style={[styles.row, styles.headerRow]}>
            <View style={styles.column}>
              <Text style={[styles.rowText, styles.headerRowText]}>Nr.</Text>
            </View>
            <View style={styles.column}>
              <Text style={[styles.rowText, styles.headerRowText]}>Name</Text>
            </View>
            {!isMobile && (
              <View style={styles.column}>
                <Text style={[styles.rowText, styles.headerRowText]}>
                  Country
                </Text>
              </View>
            )}
            <View style={styles.column}>
              <Text style={[styles.rowText, styles.headerRowText]}>Amount</Text>
            </View>
            {!isMobile && (
              <View style={styles.column}>
                <Text style={[styles.rowText, styles.headerRowText]}>
                  Last purchase
                </Text>
              </View>
            )}
          </View>
          {entries.map((entry, i) => (
            <View style={styles.row} key={i}>
              <View style={styles.column}>
                <Text style={styles.rowText}>{`${i + 1}.`}</Text>
              </View>
              <View style={styles.column}>
                <Text style={styles.rowText}>{entry.name}</Text>
              </View>
              {!isMobile && (
                <View style={styles.column}>
                  <Text style={styles.rowText}>{entry.cca2}</Text>
                </View>
              )}
              <View style={styles.column}>
                <Text style={styles.rowText}>{`${entry.total.value} ${
                  currencies[entry.total.currency].symbol
                }`}</Text>
              </View>
              {!isMobile && (
                <View style={styles.column}>
                  <Text style={styles.rowText}>{entry.lastPurchase}</Text>
                </View>
              )}
            </View>
          ))}
          <View style={styles.blur} />
          <View style={styles.availability}>
            <Text style={styles.title}>
              Currently information is only available on the app
            </Text>
            <View
              style={[
                styles.downloadButtons,
                isMobile && styles.mDownloadButtons,
              ]}
            >
              <TouchableOpacity
                onPress={() => {
                  console.log("Download android");
                }}
                style={[styles.imageButton, isMobile && styles.mImageButton]}
              >
                <Image
                  source={require("../../../../assets/svgs/android.svg")}
                  style={styles.image}
                />
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  console.log("Download apple");
                }}
                style={[styles.imageButton, isMobile && styles.mImageButton]}
              >
                <Image
                  source={require("../../../../assets/svgs/ios.svg")}
                  style={styles.image}
                />
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
    );
  }
);

const styles = StyleSheet.create({
  container: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 64,
    paddingHorizontal: 32,
  },
  title: {
    fontSize: 24,
    fontWeight: "700",
    paddingBottom: 64,
    textAlign: "center",
  },
  table: {
    width: "100%",
    height: 300,
    justifyContent: "center",
  },
  row: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    height: 32,
    paddingHorizontal: 32,
  },
  headerRow: {
    marginBottom: 24,
  },
  rowText: {
    fontSize: 24,
    fontWeight: "400",
  },
  headerRowText: {
    fontWeight: "700",
  },
  column: {
    flex: 1,
  },
  blur: {
    position: "absolute",
    height: "100%",
    width: "100%",
    backdropFilter: "blur(10px)",
  },
  availability: {
    position: "absolute",
    height: "100%",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 32,
  },
  downloadButtons: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: 16,
  },
  imageButton: {
    height: 80,
    width: 200,
  },
  image: {
    height: "100%",
    width: "100%",
    resizeMode: "contain",
  },
  // Tablet -------------------------------------------------------------------
  // Mobile -------------------------------------------------------------------
  mDownloadButtons: {
    flexDirection: "column",
    gap: 32,
  },
  mImageButton: {
    maxHeight: 140,
    width: "60%",
    resizeMode: "cover",
  },
});
