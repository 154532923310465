import { Footer } from "@components/footer";
import { Header } from "components/header";
import { useTempStore } from "@hooks/useTempStore";
import { useRef } from "react";
import {
  View,
  StyleSheet,
  ScrollView,
  ViewStyle,
  ScrollViewProps,
  LayoutChangeEvent,
  StyleProp,
} from "react-native";

type Props = {
  style?: StyleProp<ViewStyle>;
  onLayout?: (event: LayoutChangeEvent) => void;
  children: React.ReactNode;
  scrollViewProps?: Omit<ScrollViewProps, "ref">;
  headerMiddleSection?: React.ReactNode;
};

export const Screen = ({
  children,
  style,
  onLayout,
  scrollViewProps,
  headerMiddleSection,
}: Props) => {
  const scrollRef = useRef<ScrollView>(null);
  const { setScrollRef } = useTempStore();

  return (
    <View style={styles.container} onLayout={onLayout}>
      <Header middleSection={headerMiddleSection} />
      <ScrollView
        ref={scrollRef}
        {...scrollViewProps}
        onLayout={() => {
          if (scrollRef.current) {
            setScrollRef(scrollRef.current);
          }
        }}
      >
        <View style={[styles.content, style]}>{children}</View>
        <Footer />
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    flex: 1,
  },
});
