import "@expo/match-media";
import "react-native-gesture-handler";
import "expo/build/Expo.fx";
import "intl-pluralrules";

import { createElement } from "react";
import { createRoot } from "react-dom/client";
import { AppRegistry, Platform } from "react-native";
import { activateKeepAwake } from "expo-keep-awake";
import { App } from "./src/App";
import { name as appName } from "./app.json";
import "./src/utility/i18n";

import { Amplify } from "aws-amplify";
import awsconfig from "./src/aws-exports";
Amplify.configure(awsconfig);

if (__DEV__) {
  activateKeepAwake();
}

if (Platform.OS === "web") {
  require("./firebase.web");

  const rootTag = createRoot(
    document.getElementById("root") ?? document.getElementById("main")
  );
  rootTag.render(createElement(App));
} else {
  AppRegistry.registerComponent(appName, () => App);
}
