import { Screen } from "@components/screen";
import { TextContent } from "components/text-content";
import { useMediaQueries } from "hooks/useMediaQueries";
import { StyleSheet, ScrollView, Platform } from "react-native";

import { privacyPolicy } from "../../data/privacyPolicy";

export const PrivacyPolicy = () => {
  const { isTablet, isMobile } = useMediaQueries();

  return (
    <Screen
      style={[
        Platform.OS === "web" && styles.content,
        isTablet && styles.tContent,
        isMobile && styles.mContent,
      ]}
    >
      {Platform.OS === "web" ? (
        <TextContent content={privacyPolicy} />
      ) : (
        <ScrollView contentContainerStyle={styles.scrollViewContainer}>
          <TextContent content={privacyPolicy} />
        </ScrollView>
      )}
    </Screen>
  );
};

const styles = StyleSheet.create({
  content: {
    alignItems: "center",
    marginVertical: 64,
  },

  scrollViewContainer: {
    flexGrow: 1,
  },

  tContent: {
    paddingHorizontal: 64,
  },
  mContent: {
    paddingHorizontal: 32,
  },
});
