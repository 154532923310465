import { useMemo, useEffect } from "react";
import {
  StyleSheet,
  Dimensions,
  Pressable,
  Platform,
  Image,
  View,
} from "react-native";
import Animated, {
  SlideInDown,
  SlideOutDown,
  useSharedValue,
  useAnimatedStyle,
  withTiming,
} from "react-native-reanimated";
import color from "color";
import { useTempStore } from "hooks/useTempStore";

import { Text } from "@components/text";

import { colors, shadow } from "utility/styling";
import Close from "../../../assets/svgs/close-outline.svg";

const SCREEN_WIDTH = Dimensions.get("window").width;

export const Toast = () => {
  const { toast, clearToast } = useTempStore();
  const countdownAnimation = useSharedValue(0);
  const duration = toast?.duration || 4000;

  const containerStyle = useMemo(() => {
    let backgroundColor;
    switch (toast?.type) {
      case "success":
        backgroundColor = colors.SUCCESS;
        break;
      case "error":
        backgroundColor = colors.RED;
        break;
      default:
        backgroundColor = colors.WHITE;
    }

    return {
      backgroundColor,
    };
  }, [toast?.type]);

  const textStyle = useMemo(() => {
    let textColor;
    switch (toast?.type) {
      case "success":
        textColor = colors.WHITE;
        break;
      case "error":
        textColor = colors.WHITE;
        break;
      default:
        textColor = colors.BLACK;
    }

    return {
      color: textColor,
    };
  }, [toast?.type]);

  const countdownStyle = useAnimatedStyle(() => {
    return {
      width: `${countdownAnimation.value}%`,
    };
  });

  useEffect(() => {
    let timeoutOne: NodeJS.Timeout;
    let timeoutTwo: NodeJS.Timeout;
    const initializeCountdown = () => {
      timeoutOne = setTimeout(() => {
        countdownAnimation.value = withTiming(100, {
          duration: duration,
        });
        timeoutTwo = setTimeout(() => {
          clearToast();
        }, duration);
      }, 2000);

      /*await wait(2000);

      countdownAnimation.value = withTiming(100, {
        duration: duration,
      });

      await wait(duration);

      clearToast();*/
    };

    if (toast) {
      initializeCountdown();
    } else {
      countdownAnimation.value = 0;
    }

    return () => {
      clearTimeout(timeoutOne);
      clearTimeout(timeoutTwo);
    };
  }, [countdownAnimation, duration, toast, clearToast]);

  if (!toast) return null;

  return (
    <Animated.View
      style={[styles.container, containerStyle]}
      entering={SlideInDown}
      exiting={SlideOutDown}
    >
      <View style={styles.content}>
        <Animated.View style={[styles.countdown, countdownStyle]} />
        <Text style={[styles.text, textStyle]}>{toast?.message}</Text>
        <Pressable
          style={({ pressed }) => [
            {
              opacity: pressed ? 0.5 : 1,
            },
            styles.closeButton,
          ]}
          onPress={clearToast}
        >
          {Platform.OS === "web" ? (
            <Image
              source={require("../../../assets/svgs/close-outline.svg")}
              style={styles.closeIcon}
            />
          ) : (
            <Close height={24} width={24} color={colors.BLACK} />
          )}
        </Pressable>
      </View>
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    bottom: 64,
    left: 16,
    width: SCREEN_WIDTH - 32,

    ...shadow,
    borderRadius: 16,
  },
  content: {
    height: "100%",
    width: "100%",
    paddingVertical: 16,
    paddingLeft: 32,
    paddingRight: 16,
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    overflow: "hidden",
  },
  countdown: {
    height: "100%",
    left: 0,
    position: "absolute",
    backgroundColor: color(colors.WHITE).alpha(0.1).toString(),
  },
  text: {
    fontWeight: "700",
  },
  closeButton: {},
  closeIcon: {
    height: 24,
    width: 24,
  },
});
