import { Package } from "@components/package";
import { Text } from "@components/text";
import { useDimensions } from "@hooks/useDimensions";
import { forwardRef, useState } from "react";
import { View, StyleSheet, ViewProps, ScrollView } from "react-native";

type Props = Omit<ViewProps, "ref">;

export const Products = forwardRef<View, Props>(({ style, ...props }, ref) => {
  const { windowDimensions } = useDimensions();
  const [scrollViewHeight, setScrollViewHeight] = useState(430);

  return (
    <View style={[styles.container, style]} ref={ref} {...props}>
      <Text style={styles.title}>Popular options</Text>
      <View
        style={[
          styles.content,
          {
            width: windowDimensions.width,
          },
        ]}
      >
        <ScrollView
          contentContainerStyle={[
            styles.horizontalScrollViewContainer,
            scrollViewHeight && { height: scrollViewHeight },
          ]}
          style={styles.horizontalScrollView}
          showsHorizontalScrollIndicator
          horizontal
        >
          <View
            style={styles.scrollViewContent}
            onLayout={(event) => {
              const height = event.nativeEvent.layout.height;
              if (height) {
                //setScrollViewHeight(height + 10);
              }
              console.log(height);
            }}
          >
            <Package
              title="Starter"
              price={{ amountInCents: 420, currency: "USD" }}
              buttonText="I like this number"
              perks={[
                "An experience of a lifetime",
                "A chance to win a million dollars*",
                "Literally nothing else",
                "Are you still reading?",
              ]}
              clarifications={["Not really"]}
            />
            <Package
              title="Ape"
              price={{ amountInCents: 690, currency: "USD" }}
              buttonText="Don't judge me"
              perks={[
                "Better experience of a lifetime",
                "Leaderboard listing",
                "Just click the button, you will probably regret it",
              ]}
            />
            <Package
              title="Glonky"
              price={{ amountInCents: 999, currency: "USD" }}
              buttonText="Yes"
              perks={[
                "It's a lesson, that you will remember for the rest of your life",
                "Buy nothing for somebody else",
                "Leaderboard listing",
              ]}
            />
            <Package
              title="Mensa"
              price={{ amountInCents: 0, currency: "USD" }}
              buttonText="I know better"
              perks={[
                "Select custom price",
                "Random offer generator",
                "Buy nothing for somebody else",
                "Leaderboard listing",
              ]}
              special
            />
          </View>
        </ScrollView>
      </View>
    </View>
  );
});

const styles = StyleSheet.create({
  container: {
    width: "100%",
    paddingHorizontal: 32,
    alignItems: "center",
    paddingVertical: 64,
  },
  title: {
    fontSize: 24,
    fontWeight: "700",
    paddingBottom: 64,
  },
  content: {
    flexDirection: "row",
  },
  horizontalScrollViewContainer: {
    flexGrow: 1,
    justifyContent: "center",
  },
  horizontalScrollView: {},
  scrollViewContent: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
    gap: 32,
    paddingHorizontal: 32,
  },
});
