import { Text } from "@components/text";
import { generateShadow } from "@rainy5-group/react-native-shadow-generator";
import { useNavigation } from "@react-navigation/native";
import { View, StyleSheet, Image, Linking, Pressable } from "react-native";
import { useMediaQuery } from "react-responsive";

import { Newsletter } from "./Newsletter";

export const Footer = () => {
  const navigation = useNavigation();
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const openExternalLink = async (url: string) => {
    const canOpen = await Linking.canOpenURL(url);

    if (canOpen) {
      Linking.openURL(url);
    }
  };

  return (
    <View style={styles.container}>
      <Newsletter />
      <View style={[styles.top, isMobile && styles.mTop]}>
        <View style={[styles.pages, isMobile && styles.mPages]}>
          <Pressable onPress={() => console.log("go")} style={styles.page}>
            <Text style={styles.pageName}>About us</Text>
          </Pressable>
          <Pressable onPress={() => console.log("go")} style={styles.page}>
            <Text style={styles.pageName}>Discover</Text>
          </Pressable>
          <Pressable onPress={() => console.log("go")} style={styles.page}>
            <Text style={styles.pageName}>Explore</Text>
          </Pressable>
        </View>
        <View style={[styles.social, isMobile && styles.mSocial]}>
          <Pressable
            onPress={() =>
              openExternalLink("https://www.reddit.com/r/BuyLiterallyNothing/")
            }
          >
            <Image
              source={require("../../../assets/svgs/reddit-face.svg")}
              style={styles.socialLogo}
            />
          </Pressable>
          <Pressable
            onPress={() => openExternalLink("https://twitter.com/BLNProject")}
          >
            <Image
              source={require("../../../assets/svgs/twitter.svg")}
              style={styles.socialLogo}
            />
          </Pressable>
          <Pressable
            onPress={() =>
              openExternalLink("https://www.instagram.com/buyliterallynothing/")
            }
          >
            <Image
              source={require("../../../assets/svgs/instagram.svg")}
              style={styles.socialLogo}
            />
          </Pressable>
          <Pressable
            onPress={() =>
              openExternalLink("https://youtube.com/@buyliterallynothing")
            }
          >
            <Image
              source={require("../../../assets/svgs/youtube.svg")}
              style={styles.socialLogo}
            />
          </Pressable>
          <Pressable
            onPress={() =>
              openExternalLink("https://www.tiktok.com/@buyliterallynothing")
            }
          >
            <Image
              source={require("../../../assets/svgs/tiktok.svg")}
              style={styles.socialLogo}
            />
          </Pressable>
        </View>
      </View>

      <View style={styles.horizontalLineContainer}>
        <View
          style={[styles.horizontalLine, isMobile && styles.mHorizontalLine]}
        />
      </View>

      <View style={[styles.bottom, isMobile && styles.mBottom]}>
        <View style={[styles.reserved, isMobile && styles.mReserved]}>
          <Text style={styles.reservedText}>
            © 2022 Rainy5 Group. All rights reserved.
          </Text>
        </View>

        <View style={[styles.logo, isMobile && styles.mLogo]}>
          <Image
            source={require("../../../assets/svgs/logo-circle.svg")}
            style={styles.circleLogo}
          />
        </View>

        <View
          style={[styles.linksOnTheRight, isMobile && styles.mLinksOnTheRight]}
        >
          <Pressable onPress={() => navigation.navigate("TermsAndConditions")}>
            <Text style={styles.privacyPolicy}>Terms and Conditions</Text>
          </Pressable>
          <Pressable onPress={() => navigation.navigate("PrivacyPolicy")}>
            <Text style={styles.privacyPolicy}>Privacy Policy</Text>
          </Pressable>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#fff",
    ...generateShadow(5, "#000"),
    zIndex: 100,
  },

  top: {
    height: 96,
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 32,
  },
  pages: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 80,
  },
  page: {},
  pageName: {
    fontSize: 16,
    fontWeight: "500",
    color: "#000",
  },
  social: {
    width: 240,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  socialLogo: {
    height: 24,
    width: 24,
  },

  horizontalLineContainer: {
    width: "100%",
    flexDirection: "row",
  },
  horizontalLine: {
    flex: 1,
    height: 1,
    marginVertical: 50,
    marginHorizontal: 64,
    backgroundColor: "#2B3D51",
  },

  bottom: {
    height: 96,
    width: "100%",

    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 32,
  },
  reserved: {
    flex: 2,
  },
  reservedText: {
    fontSize: 14,
    fontColor: "#000",
    fontWeight: "500",
  },
  logo: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  circleLogo: {
    height: 100,
    width: 100,
  },
  linksOnTheRight: {
    flex: 2,
    flexDirection: "row",
    justifyContent: "flex-end",
    gap: 16,
  },
  privacyPolicy: {
    color: "#000",
    fontSize: 14,
    fontWeight: "500",
  },

  // Tablet -------------------------------------------------------------------

  // Mobile -------------------------------------------------------------------
  mTop: {
    height: 100,
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingHorizontal: 0,
  },
  mPages: {
    flex: 1,
    minWidth: 240,
    width: "80%",
    flexDirection: "row",
    alignItems: "center",
    gap: "unset",
  },
  mSocial: {
    flex: 1,
    alignItems: "center",
  },

  mHorizontalLine: {
    marginVertical: 25,
  },

  mBottom: {
    height: 200,
    flexDirection: "column",
    justifyContent: "flex-start",
    paddingHorizontal: 0,
  },
  mReserved: {
    flex: 1,
    order: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  mLogo: {
    flex: 2,
    order: 3,
  },
  mLinksOnTheRight: {
    flex: 1,
    order: 2,
    justifyContent: "center",
    alignItems: "center",
  },
});
