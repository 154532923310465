import { generateShadow } from "@rainy5-group/react-native-shadow-generator";
import {
  Pressable,
  StyleSheet,
  StyleProp,
  ViewStyle,
  TextStyle,
  View,
  Platform,
} from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { Flow } from "react-native-animated-spinkit";

import { Text } from "@components/text";
import { colors, gradient } from "utility/styling";

type Props = {
  onPress?: () => void;
  title?: string;
  style?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  leftIcon?: React.ReactNode;
  children?: React.ReactNode;
  special?: boolean;
  selected?: boolean;
  loading?: boolean;
  disabled?: boolean;
};

export const Button = ({
  onPress,
  title,
  style,
  textStyle,
  leftIcon,
  children,
  special = false,
  selected = false,
  loading = false,
  disabled = false,
}: Props) => {
  const isDisabled = disabled || loading;

  const renderContent = () => {
    if (loading) {
      return (
        <Flow
          size={Platform.OS === "web" ? 40 : 20}
          color={special || selected ? colors.WHITE : colors.BLACK}
        />
      );
    } else if (children) {
      return children;
    } else {
      return (
        <>
          <Text
            style={[
              styles.text,
              special && styles.specialText,
              selected && styles.selectedText,
              textStyle,
            ]}
          >
            {title ? title : "Press me!"}
          </Text>
          <View style={styles.leftIcon}>{leftIcon}</View>
        </>
      );
    }
  };

  return (
    <Pressable
      style={({ pressed }) => [
        styles.container,
        pressed && styles.pressedContainer,
        selected && styles.selectedContainer,
        children ? styles.unusualContainer : undefined,
        style,
        isDisabled && styles.disabledContainer,
      ]}
      onPress={() => {
        if (isDisabled) return;
        else if (onPress) onPress();
        else console.log("pressed");
      }}
    >
      {special && (
        <LinearGradient
          colors={gradient}
          start={{
            x: 0,
            y: 0,
          }}
          end={{
            x: 1,
            y: 1,
          }}
          style={styles.gradient}
        />
      )}

      {renderContent()}
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    opacity: 1,
    justifyContent: "center",
    alignItems: "center",

    ...Platform.select({
      web: {
        height: 70,
        borderRadius: 35,
        paddingHorizontal: 24,
        backgroundColor: "linear-gradient(145deg, #cacaca, #f0f0f0)",
        boxShadow: "5px 5px 10px #bebebe, -5px -5px 10px #ffffff",
      },
      native: {
        height: 56,
        borderRadius: 28,
        backgroundColor: colors.WHITE,
        paddingHorizontal: 20,
        ...generateShadow(2, "black"),
      },
    }),
  },
  pressedContainer: {
    ...Platform.select({
      web: {
        opacity: 0.8,
        borderWidth: StyleSheet.hairlineWidth,
        borderColor: colors.BLACK,
        backgroundColor: colors.WHITE,
        boxShadow: "unset",
      },
      native: {
        opacity: 0.6,
        ...generateShadow(1, "black"),
      },
    }),
  },
  selectedContainer: {
    backgroundColor: colors.BLACK,
  },
  disabledContainer: {
    opacity: 0.5,
  },
  gradient: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,

    ...Platform.select({
      web: {
        borderRadius: 35,
      },
      native: {
        borderRadius: 28,
      },
    }),
  },
  unusualContainer: {
    height: undefined,
  },
  text: {
    zIndex: 2,
    fontSize: 16,
    fontWeight: "800",
  },
  selectedText: {
    color: colors.WHITE,
  },
  specialText: {
    color: colors.WHITE,
  },
  leftIcon: {
    position: "absolute",
    left: 20,
  },
});
