import type { GraphQLResult } from "@aws-amplify/api";
import type { QueryFunction, QueryKey } from "@tanstack/react-query";
import { Auth } from "aws-amplify";

type Query<T> = (extraKeys?: QueryKey) => {
  key: QueryKey;
  fn: QueryFunction<T>;
};

export const queryAuthenticationDetails: Query<Types.CognitoUser> = () => ({
  key: ["authentication"],
  fn: async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();

      if (user?.username) {
        return user;
      }

      throw new Error("Couldn't authenticate the user");
    } catch (error) {
      return null;
    }
  },
});
