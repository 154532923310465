import { Text } from "@components/text";
import { StyleSheet, View } from "react-native";

type Props = {
  content: Types.TextContent;
};

export const TextContent = ({ content }: Props) => (
  <View style={styles.textContainer}>
    <Text style={styles.title}>{content.title}</Text>
    {content.sections.map((section, i) => (
      <View key={i}>
        <Text style={styles.sectionTitle}>{section.title}</Text>
        {section.paragraphs.map((paragraph, j) => (
          <View key={j} style={styles.paragraph}>
            {typeof paragraph === "string" ? (
              <Text style={styles.text}>{paragraph}</Text>
            ) : (
              <View>
                <View style={styles.bulletList}>
                  {paragraph.map((item, k) => (
                    <Text key={k} style={styles.text}>
                      {`• ${item}`}
                    </Text>
                  ))}
                </View>
              </View>
            )}
          </View>
        ))}
      </View>
    ))}
  </View>
);

const styles = StyleSheet.create({
  textContainer: {
    maxWidth: 800,
  },
  title: {
    fontSize: 32,
    marginBottom: 24,
  },
  sectionTitle: {
    fontSize: 24,
    marginBottom: 16,
  },
  text: {
    fontSize: 14,
  },
  paragraph: {
    marginBottom: 16,
  },
  bulletList: {
    marginLeft: 16,
  },
});
