import { createNativeStackNavigator } from "@react-navigation/native-stack";

import { Home } from "../../screens/home";
import { PrivacyPolicy } from "../../screens/privacy-policy";
import { TermsAndConditions } from "../../screens/terms-and-conditions";

import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const Stack = createNativeStackNavigator();

export const Navigator = () => {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey="6LcdNbUjAAAAADISy7SSvqtKwgpOQqUHYhmM1bfa"
      scriptProps={{
        async: false, // optional, default to false,
        defer: false, // optional, default to false
        appendTo: "head", // optional, default to "head", can be "head" or "body",
        nonce: undefined, // optional, default undefined
      }}
      /*container={{
        // optional to render inside custom element
        element: "[required_id_or_htmlelement]",
        parameters: {
          //badge: "[inline|bottomright|bottomleft]", // optional, default undefined
          //theme: "dark", // optional, default undefined
        },
      }}*/
    >
      <Stack.Navigator
        screenOptions={{
          headerShown: false,
        }}
      >
        <Stack.Screen name="Home" component={Home} />
        <Stack.Screen name="PrivacyPolicy" component={PrivacyPolicy} />
        <Stack.Screen
          name="TermsAndConditions"
          component={TermsAndConditions}
        />
      </Stack.Navigator>
    </GoogleReCaptchaProvider>
  );
};
