export const entries = [
  {
    name: "Elon Tusk",
    cca2: "LT",
    total: {
      value: 969,
      currency: "EUR",
    },
    lastPurchase: "2022-11-24",
  },
  {
    name: "Jeff Byzos",
    cca2: "LT",
    total: {
      value: 420,
      currency: "EUR",
    },
    lastPurchase: "2021-11-25",
  },
  {
    name: "Mac Zukerberg",
    cca2: "US",
    total: {
      value: 300,
      currency: "EUR",
    },
    lastPurchase: "2021-11-01",
  },
  {
    name: "Andrew Rainy",
    cca2: "UK",
    total: {
      value: 100,
      currency: "EUR",
    },
    lastPurchase: "2022-11-29",
  },
  {
    name: "Dariusz Rainy",
    cca2: "PL",
    total: {
      value: 99,
      currency: "EUR",
    },
    lastPurchase: "2022-12-13",
  },
];
