/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createNewsletterSubscriber = /* GraphQL */ `
  mutation CreateNewsletterSubscriber(
    $input: CreateNewsletterSubscriberInput!
    $condition: ModelNewsletterSubscriberConditionInput
  ) {
    createNewsletterSubscriber(input: $input, condition: $condition) {
      id
      email
      hostname
      score
      clientIp
      createdAt
      updatedAt
    }
  }
`;
export const updateNewsletterSubscriber = /* GraphQL */ `
  mutation UpdateNewsletterSubscriber(
    $input: UpdateNewsletterSubscriberInput!
    $condition: ModelNewsletterSubscriberConditionInput
  ) {
    updateNewsletterSubscriber(input: $input, condition: $condition) {
      id
      email
      hostname
      score
      clientIp
      createdAt
      updatedAt
    }
  }
`;
export const deleteNewsletterSubscriber = /* GraphQL */ `
  mutation DeleteNewsletterSubscriber(
    $input: DeleteNewsletterSubscriberInput!
    $condition: ModelNewsletterSubscriberConditionInput
  ) {
    deleteNewsletterSubscriber(input: $input, condition: $condition) {
      id
      email
      hostname
      score
      clientIp
      createdAt
      updatedAt
    }
  }
`;
