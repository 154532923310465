import { Text } from "@components/text";
import Icons from "@expo/vector-icons/FontAwesome";
import { generateShadow } from "@rainy5-group/react-native-shadow-generator";
import { LinearGradient } from "expo-linear-gradient";
import { View, StyleSheet, TouchableOpacity } from "react-native";

import { currencies } from "../../data/currencies";
import { styling } from "../../utility/styling";

type Props = {
  title: string;
  price: Types.Price;
  buttonText?: string;
  perks: Array<string>;
  special?: boolean;
  clarifications?: Array<string>;
};

export const Package = ({
  title,
  price,
  perks,
  special = false,
  buttonText = "Buy it",
  clarifications,
}: Props) => {
  return (
    <View style={styles.container}>
      {special && (
        <LinearGradient
          colors={styling.gradient}
          start={{
            x: 0,
            y: 0,
          }}
          end={{
            x: 1,
            y: 1,
          }}
          style={styles.gradient}
        />
      )}
      <View style={styles.content}>
        <Text style={[styles.title, special && styles.specialText]}>
          {title}
        </Text>
        <Text style={[styles.price, special && styles.specialText]}>{`${
          currencies[price.currency].symbol
        }${(price.amountInCents / 100).toFixed(2)}`}</Text>
        <TouchableOpacity
          onPress={() => console.log("buy")}
          style={[styles.button, special && styles.specialButton]}
        >
          <Text style={[styles.buttonText, special && styles.specialText]}>
            {buttonText}
          </Text>
        </TouchableOpacity>
        <View style={styles.perks}>
          {perks.map((perk, index) => (
            <View style={styles.perk} key={index}>
              <Icons
                name="check"
                size={16}
                color={special ? "white" : "black"}
                style={styles.check}
              />
              <Text style={[styles.perkText, special && styles.specialText]}>
                {perk}
              </Text>
            </View>
          ))}
        </View>
        <View style={styles.clarifications}>
          {clarifications?.map((clarification, index) => (
            <Text
              style={[styles.clarification, special && styles.specialText]}
              key={index}
            >
              {`${"*".repeat(index + 1)} ${clarification}`}
            </Text>
          ))}
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 420,
    width: 300,
    backgroundColor: "white",
    ...generateShadow(5),

    borderRadius: 30,
    padding: 35,
    overflow: "hidden",
  },
  gradient: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  content: {
    flex: 1,
  },

  title: {
    fontWeight: "700",
    fontSize: 22,
  },
  price: {
    fontWeight: "800",
    fontSize: 32,
    marginTop: 16,
    marginBottom: 32,
  },
  button: {
    width: "100%",
    borderRadius: 6,
    borderWidth: 1,
    borderColor: "black",
    height: 40,
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 32,
  },
  specialButton: {
    borderColor: "white",
  },
  buttonText: {
    fontSize: 14,
    fontWeight: "400",
  },
  perks: {
    flex: 1,
  },
  perk: {
    flexDirection: "row",
    marginBottom: 16,
  },
  check: {
    marginRight: 8,
  },
  perkText: {
    fontWeight: "400",
    fontSize: 14,
  },
  clarifications: {},
  clarification: {
    fontSize: 8,
    fontWeight: "300",
  },

  specialText: {
    color: "white",
  },
});
