import { createNativeStackNavigator } from "@react-navigation/native-stack";

import { SignIn } from "@screens/sign-in";
import { ForgotPassword } from "@screens/forgot-password";
import { SignUp } from "@screens/sign-up";
import { Verification } from "@screens/verification";
import { TermsAndConditions } from "@screens/terms-and-conditions";
import { PrivacyPolicy } from "screens/privacy-policy";

import { useAuthListener } from "hooks/useAuthListener";

const Stack = createNativeStackNavigator();

export const Onboarding = () => {
  useAuthListener();

  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen name="SignIn" component={SignIn} />
      <Stack.Screen name="SignUp" component={SignUp} />
      <Stack.Screen name="ForgotPassword" component={ForgotPassword} />
      <Stack.Screen name="Verification" component={Verification} />
      <Stack.Screen name="TermsAndConditions" component={TermsAndConditions} />
      <Stack.Screen name="PrivacyPolicy" component={PrivacyPolicy} />
    </Stack.Navigator>
  );
};
