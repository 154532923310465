import { createNativeStackNavigator } from "@react-navigation/native-stack";

import { Settings as SettingsScreen } from "@screens/settings";
import { AccountDetails } from "@screens/account-details";
import { Support } from "@screens/support";
import { PurchaseHistory } from "@screens/purchase-history";

const Stack = createNativeStackNavigator();

export const Settings = () => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen name="Settings" component={SettingsScreen} />
      <Stack.Screen name="AccountDetails" component={AccountDetails} />
      <Stack.Screen name="Support" component={Support} />
      <Stack.Screen name="PurchaseHistory" component={PurchaseHistory} />
    </Stack.Navigator>
  );
};
