import create from "zustand";
import produce from "immer";
import { Platform } from "react-native";
import { devtools, persist } from "zustand/middleware";
import AsyncStorage from "@react-native-async-storage/async-storage";

interface State {
  theme: Types.Theme;
}
interface Actions {
  setTheme: (theme: Types.Theme) => void;
  clear: () => void;
}

const cleanState: State = {
  theme: "light",
};

export const useStore = create<State & Actions>()(
  devtools(
    persist(
      (set) => ({
        ...cleanState,
        setTheme: (theme: Types.Theme) => set(() => ({ theme })),
        clear: () => set(() => produce(cleanState, () => {})),
      }),
      {
        name: "storage",
        getStorage: () => {
          if (Platform.OS === "web") return localStorage;
          return AsyncStorage;
        },
      }
    )
  )
);
