import { DefaultTheme, Theme } from "@react-navigation/native";
import { generateShadow } from "@rainy5-group/react-native-shadow-generator";

export const colors = {
  P1: "#4fff11",
  P2: "#05cdef",
  P3: "#ff00c7",
  P4: "#0b4cf4",

  WHITE: "#FFFFFF",
  BLACK: "#000",
  RED: "#FF0000",
  SUCCESS: "#00EC87",
  FACEBOOK: "#1877f2",
};
export const gradient = [colors.P1, colors.P2, colors.P3, colors.P4];
export const secondaryGradient = [colors.P2, colors.P4, colors.P3, colors.P1];
export const fonts = {
  EXTRA_LIGHT: "Roboto-Thin",
  LIGHT: "Roboto-Light",
  REGULAR: "Roboto-Regular",
  MEDIUM: "Roboto-Medium",
  SEMI_BOLD: "Roboto-Bold",
  BOLD: "Roboto-Bold",
  EXTRA_BOLD: "Roboto-Bold",
};
export const shadow = {
  ...generateShadow(5),
  backgroundColor: colors.WHITE,
};
export const navigationTheme = {
  dark: false,
  colors: {
    ...DefaultTheme.colors,
    //primary: string;
    background: colors.WHITE,
    //card: string;
    //text: string;
    //border: string;
    //notification: string;
  },
} as Theme;

export const styling = {
  colors,
  gradient,
  secondaryGradient,
  fonts,
  shadow,
  navigationTheme,
};
