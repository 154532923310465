export const currencies = {
  EUR: {
    symbol: "€",
    name: "Euro",
  },
  USD: {
    symbol: "$",
    name: "US Dollar",
  },
  GBP: {
    symbol: "£",
    name: "British Pound",
  },
};
