import create from "zustand";
import { Platform } from "react-native";
import { devtools, persist, StateStorage } from "zustand/middleware";
import { getItemAsync, setItemAsync, deleteItemAsync } from "expo-secure-store";
import produce from "immer";

interface State {
  email?: string;
  username?: string;
  password?: string;
}
interface Actions {
  setEmail: (email: string) => void;
  setUsername: (username: string) => void;
  setPassword: (password: string) => void;
  signOut: () => void;
  clear: () => void;
}

const storage: StateStorage = {
  getItem: async (name: string): Promise<string | null> => {
    console.log(name, "has been retrieved");
    return (await getItemAsync(name)) || null;
  },
  setItem: async (name: string, value: string): Promise<void> => {
    console.log(name, "with value", value, "has been saved");
    await setItemAsync(name, value);
  },
  removeItem: async (name: string): Promise<void> => {
    console.log(name, "has been deleted");
    await deleteItemAsync(name);
  },
};

const cleanState: State = {
  email: undefined,
  username: undefined,
  password: undefined,
};

export const useSecureStore = create<State & Actions>()(
  devtools(
    persist(
      (set) => ({
        ...cleanState,

        setUsername: (username: string) => set({ username }),
        setPassword: (password: string) => set({ password }),
        setEmail: (email: string) => set({ email }),
        signOut: () =>
          set({
            username: undefined,
            password: undefined,
          }),
        clear: () => set(() => produce(cleanState, () => {})),
      }),
      {
        name: "secure-storage",
        getStorage: () => {
          if (Platform.OS === "web") return localStorage;
          return storage;
        },
      }
    )
  )
);
