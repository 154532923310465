import { View, StyleSheet } from "react-native";

import { Text } from "@components/text";

type Props = {
  primaryTitle: string;
  secondaryTitle: string;
};

export const SecondaryHeader = ({ primaryTitle, secondaryTitle }: Props) => {
  return (
    <View style={styles.container}>
      <Text style={styles.primaryTitle}>{primaryTitle}</Text>
      <Text style={styles.secondaryTitle}>{secondaryTitle}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center",
    marginHorizontal: 32,
  },
  primaryTitle: {
    fontSize: 24,
    lineHeight: 32,
    fontWeight: "700",
    marginBottom: 18,
    textAlign: "center",
  },
  secondaryTitle: {
    fontSize: 16,
    lineHeight: 19,
    textAlign: "center",
  },
});
