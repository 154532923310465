import { View, StyleSheet } from "react-native";

import { Screen } from "@components/screen";
import { Text } from "@components/text";
import { Button } from "@components/button";
import { SecondaryHeader } from "@components/secondary-header";
import { Input } from "@components/input";
import { colors } from "../../utility/styling";

export const AccountDetails = () => {
  return (
    <Screen>
      <View style={styles.content}>
        <SecondaryHeader
          primaryTitle="Account details"
          secondaryTitle="So you want to make changes?"
        />

        <View style={styles.inputs}>
          <Input label="Nickname" />
          <Input label="Full name" />
          <Input label="E-mail" />
          <Input label="Password" secureTextEntry textContentType="password" />
        </View>

        <Button style={styles.button} onPress={() => {}} title="Save" />
      </View>
    </Screen>
  );
};

const styles = StyleSheet.create({
  content: {
    flex: 1,
    marginHorizontal: 32,
    justifyContent: "space-between",
  },

  inputs: {},

  button: {
    marginBottom: 16,
  },
});
